// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer_field{
    border: 1px solid #1c1c1c;
    border-radius: 6px;
    height: 40px;
    padding: 0px 10px;
}

.customer_field:focus{
    box-shadow: none;
    outline: 0px;
}

@media screen and (max-width:767px){
    .customer_field{
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) {
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/customer-facing/customer-facing.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI;QACI,mBAAmB;IACvB;AACJ;;AAEA;IACI;QAEI,aAAa;QACb,cAAc;IAClB,CAAC","sourcesContent":[".customer_field{\n    border: 1px solid #1c1c1c;\n    border-radius: 6px;\n    height: 40px;\n    padding: 0px 10px;\n}\n\n.customer_field:focus{\n    box-shadow: none;\n    outline: 0px;\n}\n\n@media screen and (max-width:767px){\n    .customer_field{\n        margin-bottom: 20px;\n    }\n}\n\n@media (min-width: 768px) {\n    .col-md-3 {\n        -ms-flex: 0 0 25%;\n        flex: 0 0 25%;\n        max-width: 25%;\n    }}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState, useRef } from 'react';

import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';

import './plan_new.css';
import LoveTravels from "../../pages/signup/love-travels";
import {
    TviWLogo
} from "../../assets/images";
import Vid from "../../assets/video/file.mp4";
import { products } from '../../products/products.details';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { setCode } from "../../actions/signUp.actions"
import { increaseHits } from '../../axios/signUp.axios';
import { Link } from "react-router-dom";
import {
    BannerImg,
    Lead1,
    Lead2,
    Lead3,
    Logo,
    Test1,
    Test2,
    Test3,
    Test4,
    Test5,
    Test6,
    Test7,
    Test8,
    Test9,
    Test10,
    Test11,
    Test12,
    Test13,
    Test14,
    Test15,
    Test16,
    Test17,
    Test18,
    Test19,
    Test20,
    Test21,
    Test22,
    Bulb,
    Pyramid,
    Globe,
    DOller,
    Smile,
    Starter,
    Enterprenour,
    Advanced,
    CertificateImg,
    FooterLogo,
} from "../../assets/images";

const AffiliatePlanPart = (props) => {
    const planRef = useRef(null);
    const params = useParams()
    const dispatch = useDispatch()
    const [affCode, SetaffCode] = useState("")
    var timer
    const callIncreaseHits = () => {
        if (timer)
            clearTimeout(timer)
        timer = () => setTimeout(() => {
            increaseHits(params.code).then((res) => {
                console.log(res.data.code_by)
                SetaffCode(res.data.code_by);
            })
                .catch((err) => {

                });

        }, 500);
        timer()
    }

    const scrollToRef = () => planRef.current.scrollIntoView();



    useEffect(
        () => {
            document.title = 'TVI Plan';
            if (!timer) {
                dispatch(setCode(affCode))
                console.log("calling")

                callIncreaseHits()
            }

        }

        , [])


    useEffect(
        () => {
            document.title = 'TVI Affiliate Plan';
            dispatch(setCode(affCode))
            console.log(affCode)
        }

        , [affCode])

    return (
        // <div className='plan_parts'>
        //     <div className='video_bg1'>
        //         {console.log(products)}

        //         <ReactPlayer 
        //             width={'100%'} 
        //             height='100%' 
        //             url={Vid}
        //             playing={true}
        //             muted={true}
        //             loop
        //             playsinline={true}
        //         />

        //     </div>
        //     <LoveTravels products={products} />
        // </div>





        <div className="full_bg">
            <section className="banner_partts">
                <h1>
                    <strong>MORE SALES.</strong>
                    <strong>MORE REFERRALS.</strong>
                    <strong>LOYAL CUSTOMERS.</strong>
                </h1>

                <div className="sub_heading">
                    <h2>
                        <strong>Boost Your Marketing</strong>(AND REVENUE) For{" "}
                        <strong>Less Than</strong> Your Daily{" "}
                        <strong className="cup_coffee">Cup Of Coffee.</strong>
                    </h2>
                </div>
                <div className="free_trial">
                    <Link to="#" onClick={scrollToRef} className="free_trial_butt">
                        Start Your Free Trial!
                    </Link>
                </div>
                <Container>
                    <div className="free_trials_lead">
                        <div className="lead_inner">
                            <img src={Lead1} />
                            <div className="lead_heading">
                                <h2>Leads</h2>
                            </div>
                            <div className="lead_para">
                                <p>
                                    Generate leads by using vacation incentives to capture your
                                    prospect's contact information.
                                </p>
                            </div>
                        </div>

                        <div className="lead_inner">
                            <img src={Lead2} />
                            <div className="lead_heading">
                                <h2>Relationships</h2>
                            </div>
                            <div className="lead_para">
                                <p>
                                    Gift your clients vacation certificates to build stronger
                                    relationships and increase your referrals.
                                </p>
                            </div>
                        </div>

                        <div className="lead_inner">
                            <img src={Lead3} />
                            <div className="lead_heading">
                                <h2>Marketing</h2>
                            </div>
                            <div className="lead_para">
                                <p>
                                    Use Vacation Giveaways to multiply your social media reach and
                                    grow your company's brand and audience.
                                </p>
                            </div>
                        </div>
                    </div>
                </Container>
                <div className="tripvalet_logo">
                    <img src={Logo} />
                </div>
            </section>
            <section className="vacation_incentive">
                <Container>
                    <div className="vacation_inner">
                        <h2>How Do Vacation Incentives Work?</h2>
                        <div className="vacation_player">
                            <ReactPlayer
                                url="https://player.vimeo.com/video/295779623?autoplay=1"
                                controls="true"
                                width="100%"
                                height={600}
                            />
                        </div>
                    </div>
                </Container>
            </section>

            <section className="gift_of_travel">
                <Container>
                    <div className="travel_container">
                        <h2>Give The Gift Of Travel</h2>
                        <p>
                            Including Some Of The Most Sought After Vacation Destinations In
                            The World!
                        </p>
                    </div>
                    <div className="travel_container2">
                        <h2>
                            How Do Realtors, Insurance Agents, and Entrepreneurs Use Vacation
                            Incentives?
                        </h2>
                        <div className="travel_cont_para">
                            <p>
                                *Generate Seller Leads Offering Vacations For Home Valuations
                            </p>
                            <p>*Generate Leads By Offering Vacation For A Free Quote</p>
                            <p>
                                *Move Potential Clients Through Your Sales Funnel By Upgrading
                                Vacation{" "}
                            </p>
                            <p>*Give Clients Vacations For Referrals</p>
                            <p>
                                *Generate Buyer Leads Giving Vacations To Open House Visitors{" "}
                            </p>
                            <p>
                                *Stay Top Of Mind By Sending Clients On Vacation With Annual
                                Evaluations{" "}
                            </p>
                            <p>
                                *Create Conversations With Prospects By Giving Birthday
                                Vacations{" "}
                            </p>
                            <p>*...The Possibilities Are Endless!! </p>
                            <p>***Vacation Incentives Can Not Be Used As An Inducement </p>
                            <p>To Purchase A Policy Or Sign A Buyer/Seller Agreement***</p>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="every_one_travel">
                <Container>
                    <div className="every_ravel_inner">
                        <h2>Everyone Loves To Travel!</h2>
                        <div className="tripvalet_logo">
                            <img src={Logo} />
                        </div>
                        <div className="free_trial">
                            <Link to="#" onClick={scrollToRef} className="free_trial_butt color2">
                                Start Your Free Trial!
                            </Link>
                        </div>
                    </div>
                </Container>
            </section>
            <div className="tvi_member_include">
                <Container>
                    <div className="tvi_member_include_white_bg">
                        <h2>What's Included In a TVI Membership?</h2>
                        <div className="tvi_member_para">
                            <p>
                                This is an online subscription-based incentive marketing system
                                with a 7-Day Free Trial. Absolutely NO Contracts or Setup Fees.{" "}
                                <span>Cancel Anytime.</span>
                            </p>
                        </div>
                        <div className="tvi_member_border_cont">
                            <ul>
                                <li>6500+ Worldwide Destinations</li>
                                <li>5 Unique Travel e-certificates</li>
                                <li>
                                    Next Level TripValet Incentives
                                    <p>*Graphic Templates</p>
                                    <p>*Fill In The Blank Social Media Prompts</p>
                                    <p>*AI Prompts</p>
                                    <p>*Copy and Past Social Media Captions</p>
                                    <p>*Image Library</p>
                                </li>
                                <li>
                                    TVI Academy
                                    <p>
                                        *Essentials Training for Facebook, Instagram, and LinkedIn
                                    </p>
                                    <p>*Marketing Training</p>
                                    <p>*Sales Training</p>
                                    <p>*Over 70 videos included and more added regularly!</p>
                                </li>
                                <li>VIP Travel Membership with Discounts Up to 70%</li>
                                <li>Add Up To 9 Friends and Family As Travel Portal Users</li>
                                <li>
                                    Benefit Hub Gives Access To Unique Shopping And Entertainment
                                    Offers
                                </li>
                                <li>Private FB Group For Collaboration Marketing Ideas</li>
                                <li>Unlimited Printable Certificates</li>
                                <li>Affiliate Links with Commission Tracking</li>
                            </ul>
                        </div>

                        <div className="free_trial">
                            <Link to="#" onClick={scrollToRef} className="free_trial_butt color2">
                                Start Your Free Trial!
                            </Link>
                        </div>
                        <h2 ref={planRef}>Select Your Membership Savings</h2>
                    </div>
                    <LoveTravels products={products} />
                </Container>
            </div>
            <section className="follow_up">
                <Container>
                    <div className="follow_up_inner">
                        <h2>
                            It Took Over A Week To Follow Up With All The Leads Generated From
                            ONE Post!
                        </h2>
                        <div className="vacation_player">
                            <ReactPlayer
                                url="https://player.vimeo.com/video/341906137?autoplay=1&controls=0"
                                controls="true"
                                width="100%"
                                height={600}
                            />
                        </div>
                        <h3>Relationship Building</h3>
                        <div className="vacation_player">
                            <ReactPlayer
                                url="https://player.vimeo.com/video/342260783?autoplay=1&controls=0"
                                controls="true"
                                width="100%"
                                height={600}
                            />
                        </div>
                    </div>
                    <div className="testimonial_inner">
                        <Row>
                            <Col lg="6" md="6" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test1} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test2} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test3} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test4} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test5} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test6} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test7} className="img-fluid" />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="testimonial_inner2">
                        <Row>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test8} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test9} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test10} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test11} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test12} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test13} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test14} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test15} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test16} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test17} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test18} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test19} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test20} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test21} className="img-fluid" />
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12">
                                <div className="test_inner_imgg">
                                    <img src={Test22} className="img-fluid" />
                                </div>
                            </Col>
                        </Row>
                        <div className="free_trial">
                            <Link to="#" onClick={scrollToRef} className="free_trial_butt color2">
                                Start Your Free Trial!
                            </Link>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="faq_sec">
                <Container>
                    <div className="faq_inner">
                        <div className="faq_hading">
                            <h2>FAQ's</h2>
                        </div>
                        <div className="faq_inner_section">
                            <div className="faq_content">
                                <div className="question_faq">
                                    How do I cancel my 7 Day Trial before I get charged?
                                </div>
                                <div className="answer_faq">
                                    <span>7 DAY CANCELLATION POLICY:</span> All cancellations must
                                    be received electronically on or before the sixth day while on
                                    the seven day trial. The credit card used to enroll will be
                                    charged on day seven. To cancel a membership, send an email
                                    with member's name and reason for cancellation to
                                    support@tripvalet.com.{" "}
                                    <span>
                                        "DUE TO THE NATURE OF OUR MEMBERSHIP NO REFUNDS ON THE
                                        MEMBERSHIP ARE AVAILABLE AFTER THE TRIAL PERIOD."
                                    </span>
                                </div>
                                <div className="faq_bulb">
                                    <img src={Bulb} />
                                </div>
                            </div>

                            <div className="faq_content">
                                <div className="question_faq">
                                    Do my customers have to take a timeshare presentation in order
                                    to take advantage of your vacation certificates?
                                </div>
                                <div className="answer_faq">
                                    No, your customers will never be required to attend any sales
                                    or timeshare presentation. TripValet Incentives is not a
                                    timeshare company or affiliated with timeshare companies.
                                    These are real, top quality vacations with no hidden fees or
                                    strings attached. Remember, we are simply filling otherwise
                                    empty rooms which the hotels and resorts turn into additional
                                    revenue from other areas of their property, such as room
                                    service, spa, in-house restaurant, bar purchases, extra day
                                    stay, etc.
                                </div>
                                <div className="faq_bulb">
                                    <img src={Pyramid} />
                                </div>
                            </div>

                            <div className="faq_content">
                                <div className="question_faq">
                                    How are you able to offer these incentives?
                                </div>
                                <div className="answer_faq">
                                    We contract with the world-leading hotel chains, cruise lines,
                                    and resorts to help them fill their otherwise empty rooms. We
                                    help fill empty rooms which the hotels, resorts, and cruise
                                    lines turn into additional revenue from other areas of their
                                    property and future bookings. Hotels rarely experience full
                                    occupancy, yet they remain open, providing rooms and services
                                    at fixed costs. So when hotels give out their rooms by
                                    partnering with marketing companies like us, they might miss
                                    out on the room fee, but they already didn't have that in the
                                    first place, at least now they have the opportunity to gain a
                                    customer for their in-house restaurants, room service, their
                                    casinos, an extra day stay, future bookings, etc. The magic of
                                    why our program works so well is the fact that it's a win-win
                                    for everyone involved. You win because you gain more business
                                    from using our incentives. Your customers win because they
                                    received an awesome incentive gift from you. The hotels,
                                    resorts, and cruise lines win because they gain additional
                                    revenue from other areas of their establishment that they
                                    otherwise would not have been able to get and they also have a
                                    new client that may book with them in the future.
                                </div>
                                <div className="faq_bulb">
                                    <img src={Globe} />
                                </div>
                            </div>

                            <div className="faq_content">
                                <div className="question_faq">
                                    Do the certificates have an expiration date?
                                </div>
                                <div className="answer_faq">
                                    Yes, all certificates created will be assigned an expiration
                                    date 30 days out. Please note that the expiration date printed
                                    on the certificates only pertains to the actual
                                    activation/redemption of the certificate. After your customers
                                    activate a vacation certificate, they will have at least 12
                                    months to travel.
                                </div>
                                <div className="faq_bulb">
                                    <img src={DOller} />
                                </div>
                            </div>

                            <div className="faq_content">
                                <div className="question_faq">
                                    Do my customers have to set their reservations and travel by
                                    the expiration date printed on the certificate?
                                </div>
                                <div className="answer_faq">
                                    No. The expiration date pertains to redemption/activation of
                                    the vacation certificate only. Once your customer
                                    redeems/activates a vacation certificate they will then have
                                    at least 12 months to travel.
                                </div>
                                <div className="faq_bulb">
                                    <img src={Smile} />
                                </div>
                            </div>

                            <div className="faq_content">
                                <div className="question_faq">
                                    Do my customers get to choose the destination?
                                </div>
                                <div className="answer_faq">
                                    Yes, your customers do get to choose the destinations that
                                    they wish to travel to.
                                </div>
                                {/* <div className="faq_bulb">
                              <img src={Smile} />
                          </div> */}
                            </div>
                            <div className="get_started_div">
                                <button type="button" onClick={scrollToRef} className="ready_started">
                                    Yes! I'm Ready To Get Started!
                                </button>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="unlock_tools">
                <Container>
                    <div className="unlock_tools_inner">
                        <h2>Unlock The Tools You Need To Expedite Your Success!</h2>
                        <div className="unlock_tools_cont">
                            <p>
                                <span className="business_heading">Dear Business Owner,</span>
                                It's no secret that we are looking at a recession in 2023, Leads
                                can be expensive to generate... The leads you do get are hard to
                                get in touch with... And clients seem to put a good deal before
                                loyalty.
                            </p>
                            <p>
                                TripValet Incentives offer Realtors, Insurance Agents, and
                                Entrepreneurs a tool to stand out from their competitors and
                                create loyal clients. By implementing vacation giveaways into
                                your marketing strategy, you can generate inexpensive leads,
                                level up your relationship marketing efforts, and be the person
                                that your friends, family, and clients refer new business too.
                            </p>
                            <p>
                                The beauty of this tool is that while you use it, to grow your
                                business, you can also share it to create another income stream!
                                Talk about working smarter, not harder!!
                            </p>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="designed_a_z">
                <Container>
                    <div className="designed">
                        <h2>
                            Designed From A-Z To Be The <span>Perfect NEXT Step</span>
                        </h2>
                        <h5>For ANY Business Level</h5>
                        <div className="starter_level">
                            <div className="starter_inner_level">
                                <div className="starter_inner_inner_level">
                                    <img src={Starter} className="img-fluid" />
                                </div>
                                <h2>STARTER</h2>
                                <p>
                                    We all start somewhere... and today, you could be sitting here
                                    wondering if this is right for you as a "starter". The answer
                                    is a 100% YES. We've designed this program so even a brand new
                                    starter can take immediate action and make this work.
                                </p>
                            </div>

                            <div className="starter_inner_level">
                                <div className="starter_inner_inner_level">
                                    <img src={Enterprenour} className="img-fluid" />
                                </div>
                                <h2>ENTREPRENEUR</h2>
                                <p>
                                    You may have a traditional business or one online, but want to
                                    accelerate your results. You'll be able to see the strategies
                                    and implement.{" "}
                                </p>
                            </div>

                            <div className="starter_inner_level">
                                <div className="starter_inner_inner_level">
                                    <img src={Advanced} className="img-fluid" />
                                </div>
                                <h2>ADVANCED</h2>
                                <p>
                                    For all of my established Entrepreneurs who are actively
                                    seeking out ways to differentiate, launch something fresh,
                                    capture more profit while connecting with your customers, and
                                    move in a powerful, high-level way -- you'll be inspired and
                                    instantly have insights to implement.{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="business_training">
                <Container>
                    <div className="training_content">
                        <h2>
                            Benefit From Hands On Business Training, Access To 1-1 Business
                            Execution Coaching, Live Group Coaching, Live Virtual Workshops
                            and Masterminds, AND Member Benefit Programs That Put Money Back
                            In Your Pocket!
                        </h2>
                        <h3>All For Just $97!!</h3>
                        <div className="get_started_div py-5">
                            <button type="button" onClick={scrollToRef} className="ready_started">
                                Yes! I'm Ready To Get Started!
                            </button>
                        </div>
                    </div>
                    <div className="certificate_img">
                        <img src={CertificateImg} className="img-fluid" />
                    </div>
                    <div className="get_started_div py-5">
                        <button type="button" onClick={scrollToRef} className="ready_started">
                            Yes! I'm Ready To Get Started!
                        </button>
                    </div>
                </Container>
            </section>
            <section className="footer_partts">
                <Container>
                    <div className="footer_inner_partts">
                        <Row>
                            <Col lg="6" md="6">
                                <div className="footer_inner_logo">
                                    <img src={FooterLogo} />
                                </div>
                            </Col>
                            <Col lg="6" md="6">
                                <div className="footer_right_text">
                                    <p>Copyright 2024-TripValet Incentives All Rights Reserved</p>
                                    <div className="privacy_div">
                                        <Link to="#">Privacy Policy</Link>
                                        <Link to="#">Terms & Conditions</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </div>







    );
};

export default AffiliatePlanPart;
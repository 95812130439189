import React, { useState, useEffect } from "react";
//import "./styles.css";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import LeftpanelMember from "../../../../components/leftpanel/leftpanel-member";
import HeaderPart from "../../../../components/layout/header/header";
import LoadingOverlay from "react-loading-overlay";
import "./tvi-academy.css";


import {
    FacebookEssentials,
    InstagramEssentials,
    LinkedinEssentials,
    ConfidenceEssentials,
    PsychologyMarketing,
    PUblicSpeaking,
    SalesEssentials,
    NegotiationPrinciples,
} from "../../../../assets/images";

const TravelDashboardPart = () => {
    return (
        <div>
            <HeaderPart />
            <div className="main_cont">
                <LeftpanelMember />
                <div className="right_cont">
                    <div className="right_banner_banner_cnt">
                        <Row>
                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8JQk_0nW2NfggmanSRSmyYU" target="_blank" className="banner1_box travel_boxxx">
                                        <img src={FacebookEssentials} className="img-fluid" />
                                        <div className="banner1_overlay">Facebook Essentials</div>
                                    </a>
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8JQ5ZnJSoKs6cx4FanjGTe1" target="_blank" className="banner1_box travel_boxxx">
                                        <img src={InstagramEssentials} className="img-fluid" />
                                        <div className="banner1_overlay">Instagram Essentials</div>
                                    </a>
                                </div>
                            </Col>

                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8IjUe3zCmHGMr_F0Exld0Kv" target="_blank" className="banner1_box travel_boxxx">
                                        <img src={LinkedinEssentials} className="img-fluid" />
                                        <div className="banner1_overlay">Linkedin Essentials</div>
                                    </a>
                                </div>
                            </Col>

                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8KKuaVfLyT-T8K4YHl7G_w9" target="_blank" className="banner1_box travel_boxxx">
                                        <img src={ConfidenceEssentials} className="img-fluid" />
                                        <div className="banner1_overlay">Confidence Essentials</div>
                                    </a>
                                </div>
                            </Col>

                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8LjAuo0bKf3h-lOfbJQ6EXS" target="_blank" className="banner1_box travel_boxxx">
                                        <img src={PsychologyMarketing} className="img-fluid" />
                                        <div className="banner1_overlay">Psychology of Marketing</div>
                                    </a>
                                </div>
                            </Col>


                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8LeOUcdLtlJGLiP39UZdeL8" target="_blank" className="banner1_box travel_boxxx">
                                        <img src={PUblicSpeaking} className="img-fluid" />
                                        <div className="banner1_overlay">Public Speaking Essentials</div>
                                    </a>
                                </div>
                            </Col>

                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8KH4SqGrKBsjkLEc9JvbkqY" target="_blank" className="banner1_box travel_boxxx">
                                        <img src={SalesEssentials} className="img-fluid" />
                                        <div className="banner1_overlay">Sales Essentials</div>
                                    </a>
                                </div>
                            </Col>

                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8JzUrdeANEGI8WO7_-vNvv7" target="_blank" className="banner1_box travel_boxxx">
                                        <img src={NegotiationPrinciples} className="img-fluid" />
                                        <div className="banner1_overlay">9 Negotiation Principles</div>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TravelDashboardPart;
import React, { useState, useEffect } from "react";
//import "./styles.css";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import LeftpanelMember from "../../../components/leftpanel/leftpanel-member";
import HeaderPart from "../../../components/layout/header/header";
import LoadingOverlay from "react-loading-overlay";
import "./travel-certificate-dashboard.css";


import {
    EmailBanner,
    PrintBg,
    EmbedBanner,
} from "../../../assets/images";

const TravelDashboardPart = () => {
    return (
        <div>
            <HeaderPart />
            <div className="main_cont">
                <LeftpanelMember />
                <div className="right_cont">
                    <div className="right_banner_banner_cnt">
                        <Row>
                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="/travel-certificates" className="banner1_box travel_boxxx">
                                        <img src={EmailBanner} className="img-fluid" />
                                        <div className="banner1_overlay">Email</div>
                                    </a>
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="/print" className="banner1_box travel_boxxx">
                                        <img src={PrintBg} className="img-fluid" />
                                        <div className="banner1_overlay">Print</div>
                                    </a>
                                </div>
                            </Col>

                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="/embed-code" className="banner1_box travel_boxxx">
                                        <img src={EmbedBanner} className="img-fluid" />
                                        <div className="banner1_overlay">Embed Code</div>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TravelDashboardPart;
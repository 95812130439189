import React, { useEffect } from "react";
import "./realestate.css";
import ReferalFooter from "../reffer/ReferalFooter";
import ReferalHeader from "../reffer/ReferalHeader";
import {
  incentives_bg,
  realestate_banner,
  sec_alt_img_four,
  sec_alt_img_one,
  sec_alt_img_three,
  sec_alt_img_two,
  Test20,
  Test21,
  Test22,
  Test23,
  Test24,
  Test25,
  Test26,
  Test27,
  Test28,
  Test29,
  vacation_incentives,
} from "../../assets/images";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Buffer } from "buffer";

export default function RealEstate() {
  const TESTIMONIALS = [
    Test20,
    Test21,
    Test22,
    Test23,
    Test24,
    Test25,
    Test26,
    Test27,
    Test28,
    Test29,
  ];
  const history = useHistory();
  const gotoOrderCheckoutForm = () => {
    if (localStorage.getItem('referCodeStore')) {
      history.push(
        "/order-check-out?encode=" +
        Buffer.from(localStorage.getItem('referCodeStore'), "utf-8").toString("base64")
      );
    } else {
      history.push(
        "/order-check-out"
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="landing">
      <ReferalHeader
        gotoOrderCheckoutForm={gotoOrderCheckoutForm}
      />

      <section
        className="industries_banner position-relative text_right"
        style={{
          background: "url(" + realestate_banner + ")",
        }}
      >
        <Container>
          <Row>
            <Col md={6} sm={6} className="ib_content position-relative">
              <h2>How Realtors Use Vacation Incentives</h2>
              <p>
                Boost traffic to your open houses...
                <br />
                Get more listings...
                <br />
                Create loyal clients...
                <br />
                ...by offering high value vacation incentives!
              </p>
              <a href="javascript:void(0)" onClick={gotoOrderCheckoutForm} className="btn btn-style-two">
                Start A FREE 7-Day Trial
              </a>
            </Col>
          </Row>
        </Container>
      </section>

      <section
        className="incentives text-white"
        style={{
          background: "url(" + incentives_bg + ")",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="text-center incentive_text">
                <p className="mb-0">
                  TripValet Incentives can help Real Estate professionals, in
                  any market, grow their business.
                  <br />
                  High value vacation incentives are helping Realtors generate
                  leads and referrals, grow their brand, and acquire more
                  customers.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sec_style_one text-white">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="sso_heading text-center">
                <h2>
                  TOP WAYS FOR A REALTOR TO USE
                  <br />
                  VACATION INCENTIVES
                </h2>
              </div>
            </Col>
            <Col md={4} className="sso_listing">
              <ul>
                <li>Increase traffic to open houses</li>
                <li>In exchange for home valuations</li>
                <li>Closing gifts</li>
                <li>
                  Appreciation gifts (nurses week, teachers, local first
                  Responders, etc
                </li>
                <li>Client anniversary gifts</li>
                <li>Google Reviews</li>
              </ul>
            </Col>
            <Col md={4} className="sso_listing">
              <ul>
                <li>Building your email list</li>
                <li>Running contests on social media</li>
                <li>Conventions and trade shows</li>
                <li>Gift for referral partners</li>
                <li>Birthday and anniversary gifts</li>
                <li>Networking events</li>
              </ul>
            </Col>
            <Col md={10}>
              <img
                style={{ marginTop: "-2rem" }}
                src={vacation_incentives}
                alt="Vacation Incentives"
                className="img-fluid"
              />
            </Col>
          </Row>
          <div className="text-center mt-4">
            <a to="#" onClick={gotoOrderCheckoutForm} class="btn btn-style-two">
              Start A FREE 7-Day Trial
            </a>
          </div>
        </Container>
      </section>

      <section className="sec_style_two text-white">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="sst_heading text-center">
                <h2>
                  Are there any RESPA or State Regulations I need to be
                  concerned about?
                </h2>
              </div>
            </Col>
            <Col md={8} className="sst_listing">
              <p className="text-center">
                Incentives can not be used as an inducement to list or buy.
                <br />
                Used in examples like these are acceptable case uses…
              </p>
              <ul>
                <li>
                  During information gathering and sharing phases: Ex: OptIns
                  for MLS Search, CMA’s, FSBOs, Expired Listings, Search Drip
                  Campaigns
                </li>
                <li>
                  Showing appreciation: Ex: After closing on a home, Referrals,
                  Home Anniversary, Google Reviews, Birthdays, Anniversaries,
                  National Nurses Week
                </li>
                <li>
                  Lead generation: Ex: Networking, Online Contests, Booths,
                  Trade Shows
                </li>
              </ul>
            </Col>
          </Row>
          <div className="text-center mt-4">
            <a href="javascript:void(0)" onClick={gotoOrderCheckoutForm} class="btn btn-style-two">
              Start A FREE 7-Day Trial
            </a>
          </div>
        </Container>
      </section>

      <section className="sec_alt text-white">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="mb-5">
                <Row className="align-items-center">
                  <Col md={7} className="sec_alt_img">
                    <img
                      src={sec_alt_img_one}
                      className="img-fluid"
                      alt="TripValet Incentives Realtor Short Webinar Fuel Presentation July 2023"
                    />
                  </Col>
                  <Col md={5} className="sec_alt_txt">
                    <h2>
                      1. Why
                      <br />
                      Does
                      <br />
                      It
                      <br />
                      Work?
                    </h2>
                  </Col>
                </Row>
              </div>
              <div className="mb-5">
                <Row className="align-items-center">
                  <Col md={{ span: 7, order: 2 }} className="sec_alt_img">
                    <img
                      src={sec_alt_img_two}
                      className="img-fluid"
                      alt="TripValet Incentives Realtor Short Webinar Fuel Presentation July 2023"
                    />
                  </Col>
                  <Col md={{ span: 5, order: 1 }} className="sec_alt_txt">
                    <h2>
                      2. How
                      <br />
                      Does
                      <br />
                      It
                      <br />
                      Work?
                    </h2>
                  </Col>
                </Row>
              </div>
              <div className="mb-5">
                <Row className="align-items-center">
                  <Col md={7} className="sec_alt_img">
                    <img
                      src={sec_alt_img_three}
                      className="img-fluid"
                      alt="TripValet Incentives Realtor Short Webinar Fuel Presentation July 2023"
                    />
                  </Col>
                  <Col md={5} className="sec_alt_txt">
                    <h2>
                      3. Five Vacation
                      <br />
                      Offers
                      <br />
                      To Choose From
                    </h2>
                  </Col>
                </Row>
              </div>
              <div className="mb-5">
                <Row className="align-items-center">
                  <Col md={{ span: 7, order: 2 }} className="sec_alt_img">
                    <img
                      src={sec_alt_img_four}
                      className="img-fluid"
                      alt="TripValet Incentives Realtor Short Webinar Fuel Presentation July 2023"
                    />
                  </Col>
                  <Col md={{ span: 5, order: 1 }} className="sec_alt_txt">
                    <h2>
                      4. What Does
                      <br />
                      The Client
                      <br />
                      Do?
                    </h2>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div className="text-center mt-4">
            <a to="#" onClick={gotoOrderCheckoutForm} className="btn btn-style-three">
              Start A FREE 7-Day Trial
            </a>
          </div>
        </Container>
      </section>
      <section className="tes_grid">
        <Container>
          <Row className="justify-content-center">
            <Col md={9}>
              <Row>
                {TESTIMONIALS.map((item, key) => {
                  return (
                    <Col md={6} className="tes_grid_item" key={key}>
                      <img alt="item" src={item} className="img-fluid w-100" />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
          <div class="text-center mt-4">
            <a to="#" onClick={gotoOrderCheckoutForm} class="btn btn-style-three">
              Start A FREE 7-Day Trial
            </a>
          </div>
        </Container>
      </section>

      <ReferalFooter
        gotoOrderCheckoutForm={gotoOrderCheckoutForm}
      />
    </div>
  );
}

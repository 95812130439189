import React, { useState } from 'react';
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import './plan.css';
import useFormValidation from '../../hooks/useFormValidator';

import { createPayment } from '../../axios/signUp.axios';
import { useSelector, useDispatch } from 'react-redux';
import { setLogin, setPayment } from '../../actions/signUp.actions';
import { message } from "antd";
import { NotificationContainer } from 'react-notifications';
import terms from '../../assets/terms/terms_condition.pdf';
const Payment = (props) => {
    const tempUser = useSelector(state => state.changeStep.tempuser)
    const product = useSelector(state => state.product.details)
    const dispatch = useDispatch();
    const [checkBox, setCheckbox] = useState(false);

    const [input, setInput] = useState({
        cardNumber: "",
        cardName: "",
        cardExpMonth: "",
        cardExpYear: "",
        cardCVV: "",
        amount: product.price

    })
    const [validationReport, validate] = useFormValidation()

    const handleCheckboxChange = () => {
        setCheckbox(!checkBox);
    };
    const change = async (wizard) => {
        if (checkBox) {
            console.log("userDetails", tempUser)
            let temp = {
                userDetails: tempUser,
                ...input,
                price: product.price_id,
                coupon: product.coupon,
                priceObject: product.priceObject
            }
            if (product.coupon)
                temp.coupon = product.coupon
            console.log(temp, "temp")
            props.loader(true)
            await createPayment(temp)
                .then(res => {
                    if (res.data.status) {

                        // dispatch(setTempUser(temp))
                        dispatch(setPayment(res.data.data.paymentDetails))
                        dispatch(setLogin(res.data.data.dashboardURL))
                        props.notify("Payment was successfull ", true)
                        props.changeWizard(wizard)

                    }
                    else {
                        props.notify("Payment was not successfull. Error: " + res.data.message)
                        // message.error("Payment was not successfull. Error: "+ res.data.message)
                    }
                    props.loader(false)
                })
                .catch(err => {
                    props.notify("Payment was not successfull. Error: " + err.message, false)
                    props.loader(false)
                })
        } else {
            props.notify("Please check this box if you want to proceed", false)
        }

    }
    const handleChange = (event) => {
        let rules = JSON.parse(event.target.dataset.rules)
        validate(event.target.value, rules, event.target.name)
        setInput({ ...input, [event.target.name]: event.target.value })
    }

    return (
        <div className='sign_up_partts'>
            <h3>3. Payment</h3>
            <p>Dear {tempUser.firstName}, Please proceed with your payment details...</p>
            <Form>
                <div className='payment_boxxx'>
                    <NotificationContainer />
                    <p><b>Your Payment is secure</b></p>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Card Number*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Card Number"
                            className='sign_email'
                            name="cardNumber"
                            value={input.cardNumber}
                            data-rules={JSON.stringify({
                                required: true,
                                creditcard: true
                            })}
                            onChange={(event) => { handleChange(event) }}

                        />
                        {
                            validationReport.cardNumber && !validationReport.cardNumber.isValid ? <small className='text-danger'>{validationReport.cardNumber.errorMessage} </small> : ""
                        }
                    </Form.Group>
                    <Row>
                        <Col lg="6" md="12">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Expiration Date*</Form.Label>
                                <Row>
                                    <Col lg="4" md="12">
                                        <Form.Control
                                            type="text"
                                            format="MM"
                                            placeholder="MM"
                                            className='sign_email'
                                            minLength={2}
                                            name="cardExpMonth"
                                            value={input.cardExpMonth}
                                            data-rules={JSON.stringify({
                                                required: true,
                                                month: true,
                                                minlength: 2
                                            })}
                                            onChange={(event) => { handleChange(event) }}

                                        />
                                        {
                                            validationReport.cardExpMonth && !validationReport.cardExpMonth.isValid ? <small className='text-danger'>{validationReport.cardExpMonth.errorMessage} </small> : ""
                                        }
                                    </Col>
                                    <Col lg="8" md="12">
                                        <Form.Control
                                            type="text"
                                            placeholder="YY"
                                            format="YYYY"
                                            className='sign_email'
                                            minLength={4}
                                            maxLength={4}
                                            name="cardExpYear"
                                            value={input.cardExpYear}
                                            data-rules={JSON.stringify({
                                                required: true,
                                                creditcard: true,
                                                minlength: 4,
                                                maxlength: 4,
                                                year: true
                                            })}
                                            onChange={(event) => { handleChange(event) }}

                                        />
                                        {
                                            validationReport.cardExpYear && !validationReport.cardExpYear.isValid ? <small className='text-danger'>{validationReport.cardExpYear.errorMessage} </small> : ""
                                        }
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        <Col lg="6" md="12">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Security Code (CVV )*</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="XXX"
                                    className='sign_email'
                                    name="cardCVV"
                                    value={input.cardCVV}
                                    data-rules={JSON.stringify({
                                        required: true,
                                        cvv: true
                                    })}
                                    onChange={(event) => { handleChange(event) }}

                                />
                                {
                                    validationReport.cardCVV && !validationReport.cardCVV.isValid ? <small className='text-danger'>{validationReport.cardCVV.errorMessage} </small> : ""
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Card Holder Name*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            className='sign_email'
                            name="cardName"
                            value={input.cardName}
                            data-rules={JSON.stringify({
                                required: true
                            })}
                            onChange={(event) => { handleChange(event) }}
                        />
                        {
                            validationReport.cardName && !validationReport.cardName.isValid ? <small className='text-danger'>{validationReport.cardName.errorMessage} </small> : ""
                        }
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Save card details for next time" />
            </Form.Group> */}

                </div>
                <Form.Group className="my-4" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox"
                        checked={checkBox}
                        onChange={handleCheckboxChange}
                        id='terms'
                    />
                    <label for='terms'>I have read and accept the <a href={terms} target='_blank'>Membership Policy</a>.</label>
                </Form.Group>

                <div className='sign_submit justify-content-start w-100'>
                    <Button variant="primary" type="button" className='w-100' onClick={() => { change("success") }}>
                        Buy Now
                    </Button>
                </div>
            </Form>

        </div>
    );
};

export default Payment;
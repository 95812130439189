import React, { useState, useEffect } from "react";
//import "./styles.css";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import LeftpanelMember from "../../../components/leftpanel/leftpanel-member";
import HeaderPart from "../../../components/layout/header/header";
import LoadingOverlay from "react-loading-overlay";
import "../travel-certificate-dashboard/travel-certificate-dashboard.css";
import axios from "../../../axios/axios";



import {
    EmailBanner,
    PrintBg,
    EmbedBanner,
} from "../../../assets/images";




const PreferredPartnerTab = () => {
    const [loading, setLoading] = useState(false);
    const [partners, setPartners] = useState([]);

    const getPartners = async () => {
        setLoading(true);

        try {
            await axios
                .get(`/get-preferred-partner`)
                .then((res) => {
                    if (res.data.status === true) {
                        setPartners(res.data.data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPartners();
    }, []);


    return (
        <div>
            <HeaderPart />
            <LoadingOverlay active={loading} spinner text="Loading ...">
                <div className="main_cont">
                    <LeftpanelMember />
                    <div className="right_cont">
                        <div className="right_banner_banner_cnt">
                            {(partners.length > 0) ? (<>
                                <Row>

                                    {partners.length > 0 &&
                                        partners.map((part) => {
                                            return (

                                                <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                                    <div className="right_inner_inner_banner">
                                                        <a href={part.url} target="_blank" className="banner1_box travel_boxxx">
                                                            <img src={part.imageUrl} className="img-fluid" />
                                                            <div className="banner1_overlay">{part.title}</div>
                                                        </a>
                                                    </div>
                                                </Col>
                                            );
                                        })}


                                </Row>
                            </>) : (<>
                                <h5 className="text-center">No Data Found</h5>
                            </>)}
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </div>

    );
};

export default PreferredPartnerTab;
import React from "react";
//import "./styles.css";
import "./success.css";
import "react-datepicker/dist/react-datepicker.css";
import { Logo, PaymntSuccess } from "../../../assets/images";

const SuccessPage = () => {
  return (
    <div>
      <div className="main_cont stripe_cont">
        {/* <h2>Hello, John!</h2> */}
        <div className="paymnt_box">
          <div className="pay_logo">
            <img src={Logo} alt="" />
          </div>
          <div className="success_page">
            <div className="success_inner_page">
              <div className="paymn_success">
                <img src={PaymntSuccess} />
              </div>
              <div className="paymnt_text">
                <h2>Payment Success!</h2>
                <p>Check your Email for your Vacation Booking Instructions”</p>
                {/* <div className='continue_but'><a href='/'>Download Receipt</a></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;

import React, { useState, useEffect } from "react";
import LeftpanelMember from "../../../components/leftpanel/leftpanel-member";
import HeaderPart from "../../../components/layout/header/header";
import "./print.css";
import LoadingOverlay from "react-loading-overlay";
import axios from "../../../axios/axios";
import { message } from "antd";

import { Col, Row } from "react-bootstrap";

const Print = () => {
  const [loading, setLoading] = useState(false);
  const [certis, setAllCertis] = useState([]);

  const fetchCertis = async () => {
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("AuthToken")}` },
    };
    axios
      .get("/get-all-travel-certificate", config)
      .then((res) => {
        setLoading(false);

        if (res.data.status) {
          setAllCertis(res.data.all_travel_certificate);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        message.error("Something went wrong");
      });
  };
  useEffect(() => {
    fetchCertis();
  }, []);

  return (
    <LoadingOverlay active={loading} spinner text="loading...">
      <HeaderPart />
      <div className="main_cont">
        <LeftpanelMember />

        <div className="right_cont">
          <div className="right_banner_banner_cnt">
            <div className="travel_deals_admin">
              <h2>Print Gallery</h2>

              <div className="pdf_inner_partts">
                <div className="pdf_box">
                  <div className="pdf_innner_box">
                    {certis.map((certificate, key) => {
                      if (certificate.certificate_code != "SFX5NIGHT" && certificate.certificate_code != "SFX7NIGHT") {
                        return (
                          <div className="pdf_inner_inner_box" key={key}>
                            <div className="pdf_boxi">
                              <a
                                href={`/travel-certificate-details/${certificate._id}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div className="create_pdfff">
                                  <div className="pdf_banner">
                                    <img
                                      src={certificate.banner}
                                      alt="Travel Certificate"
                                    />
                                  </div>
                                  <div className="pdf_content">
                                    <Row>
                                      <Col lg="3" md="4" sm="12">
                                        <div className="pdf_price_img">
                                          <img
                                            src={certificate.pricetag}
                                            className="img-fluid"
                                            alt="tag"
                                          />
                                        </div>
                                        <div className="scan_code">
                                          <img
                                            src={`https://chart.googleapis.com/chart?cht=qr&chl=${certificate.qr_code}%2F&chs=180x180&choe=UTF-8&chld=L%7C2`}
                                            className="img-fluid"
                                            alt="qr"
                                          />
                                        </div>
                                        {/* <div className="pdf_logo_img">
                                        <img
                                          src={logo_image}
                                          className="img-fluid"
                                          alt="img"
                                        />
                                      </div> */}
                                      </Col>
                                      <Col lg="9" md="8" sm="12">
                                        <div className="certificate_terms">
                                          <h2>Terms of the Certificate</h2>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                certificate.certificate_description,
                                            }}
                                          />

                                          <h3>GET YOUR CERTIFICATE TODAY!</h3>
                                          <div className="go_online">
                                            <p>
                                              Go Online:
                                              http://redeem.tripvalet.com
                                            </p>
                                            <p>
                                              Enter Referral Code: (Member
                                              Username)
                                            </p>
                                            <p>Certificate Code: 40A</p>
                                          </div>
                                          <div className="foot_cont">
                                            <p>
                                              If you have general questions about
                                              your certificate please email
                                              support@tripvalet.com Monday to
                                              Friday from 9am to 5pm Central Time.
                                            </p>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </a>
                            </div>
                            {/* <div
                            onClick={() => {
                              this.setState({
                                show: true,
                                certificate_id: certificate._id,
                              });
                            }}
                            className="cross"
                          >
                            X
                          </div> */}
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Print;

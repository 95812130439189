import axios from "./axios";
export const commissionStats =  (userId) =>{
    return new Promise((resolve,reject)=>{
         axios
        .post(`/user/affiliate-dashboard-stats/${userId}`)
        .then((res) => {
            if (res.data.status) {        
                resolve(res.data.data)
            } else {
                reject(res.data.message)
            }
        })
        .catch((err) => {        
            reject(err.response.data.message);
        });
    })
   
}
export const commissionBarChart =  (userId,filters = {}) =>{
    return new Promise((resolve,reject)=>{
         axios
        .post(`/user/commission-bar-chart/${userId}`, filters)
        .then((res) => {
            if (res.data.status) {        
                resolve(res.data.data)
            } else {
                reject(res.data.message)
            }
        })
        .catch((err) => {        
            reject(err.response.data.message);
        });
    })
   
}
export const getAffiliateChildren =  (userId) =>{
    return new Promise((resolve,reject)=>{
         axios
        .post(`/user/affiliate-children/${userId}`)
        .then((res) => {
            if (res.data.status) {        
                resolve(res.data.data)
            } else {
                reject(res.data.message)
            }
        })
        .catch((err) => {        
            reject(err.response.data.message);
        });
    })
   
}
export const getCommission =  (userId) =>{
    return new Promise((resolve,reject)=>{
         axios
        .get(`/user/commissions/${userId}`)
        .then((res) => {
            if (res.data.status) {        
                resolve(res.data.data)
            } else {
                reject(res.data.message)
            }
        })
        .catch((err) => {        
            reject(err.response.data.message);
        });
    })
   
}
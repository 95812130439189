import React from 'react';
//import "./styles.css";
import './failed.css';
import "react-datepicker/dist/react-datepicker.css";
import {
    Logo,
    PaymntFailed,
} from "../../../assets/images";


const FailedPage = () => {
    return (
            <div>
                <div className='main_cont stripe_cont'>
                    {/* <h2>Hello, John!</h2> */}
                    <div className='paymnt_box'>
                        <div className='pay_logo'>
                            <img src={Logo} alt=""/>
                        </div>
                        <div className='success_page'>
                            <div className='success_inner_page'>
                                <div className='paymn_success'>
                                    <img src={PaymntFailed} />
                                </div>
                                <div className='paymnt_text failed'>
                                    <h2>Payment Failed!</h2>
                                    <p>It seems we have not received money</p>
                                    <div className='continue_but'><a href='/'>Try Again</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           );
        };
        
        export default FailedPage;
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "../../../axios/axios";
import { message } from "antd";
import "./customer-facing.css";
const TravelDashboardPart = () => {
  const { certificateId, sender_id, source_domain } = useParams();

  const [certificateDetails, setCertificatedetails] = useState({});
  const [input, setInput] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  useEffect(() => {
    console.log('Check')
    getCertificateDetails();
  }, []);

  const getCertificateDetails = () => {
    axios
      .get(`/get-certificate-details/${certificateId}`)
      .then((res) => {
        if (res.data.status) {
          setCertificatedetails(res.data.certificateDetails);
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  const handleOnChange = (e) => {
    let { name, value } = e.target;

    setInput({
      ...input,
      [name]: value,
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();

    let data = {
      ...input,
      certificateId,
      sender_id,
      source_domain
    };

    axios
      .post("/send-embed-certificate", data)
      .then((res) => {
        if (res.data.status) {
          message.success(res.data.message);
          setInput({
            first_name: "",
            last_name: "",
            email: "",
          });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  return (
    <Form onSubmit={formSubmit} className="py-4">
      <h3>{certificateDetails.certificateTitle}</h3>
      <Row>
        <Col md="3">
          <Form.Control
            type="text"
            placeholder="First Name"
            className="customer_field"
            required
            name="first_name"
            onChange={handleOnChange}
          />
        </Col>
        <Col md="3">
          <Form.Control
            type="text"
            placeholder="Last Name"
            className="customer_field"
            required
            name="last_name"
            onChange={handleOnChange}
          />
        </Col>
        <Col md="3">
          <Form.Control
            type="email"
            placeholder="Email Address"
            className="customer_field"
            required
            name="email"
            onChange={handleOnChange}
          />
        </Col>
        <Col md="3">
          <Button type="submit" className="d-block">
            SUBMIT
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default TravelDashboardPart;

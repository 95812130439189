import React, { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import './plan.css';
import {
    PaymntFailed, TviWLogo
} from "../../assets/images";
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import ReactPlayer from 'react-player';
import Vid from "../../assets/video/file.mp4";
const OrderCheckoutPaymentFailed = (props) => {
    const history = useHistory();
    useEffect(() => {
        const script = document.createElement("script");

        script.src = window.location.origin + "/custom_script.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    })
    return (
        <div className='plan_parts'>
            <div className='video_bg1 plan_bg_box'>

                <ReactPlayer
                    width={'100%'}
                    height='100%'
                    url={Vid}
                    playing={true}
                    muted={true}
                    loop
                />

            </div>
            <div className='loves_travel'>
                <Container>
                    <div className='loves_travel_inner'>
                        <h2>EVERYONE LOVES TRAVEL.</h2>
                        <div className='tvi_w_logo'>
                            <img src={TviWLogo} alt="" />
                        </div>
                    </div>
                </Container>
                <div className='trip_membership'>
                    <div className='sign_up_partts paymnt_parttss'>
                        <div className="paymn_success">
                            <img src={PaymntFailed} />
                        </div>
                        <div className="paymnt_text">
                            <h2 className='text-danger'>Payment Failed!</h2>
                            <p className='text-center'>Please try again!</p>
                            <div className='text-center'>
                                <button className='btn btn-primary' onClick={() => {
                                    history.push("/order-check-out")
                                }}>Back to Checkout Page</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        // <div className='sign_up_partts paymnt_parttss'>
        //     <div className="paymn_success">
        //         <img src={PaymntFailed} />
        //     </div>
        //     <div className="paymnt_text">
        //         <h2 className='text-danger'>Payment Failed!</h2>
        //         <p className='text-center'>Please try again!</p>
        //         <div className='text-center'>
        //             <button className='btn btn-primary' onClick={() => {
        //                 history.push("/order-check-out")
        //             }}>Back to Checkout Page</button>
        //         </div>
        //     </div>
        // </div>
    );
};

export default OrderCheckoutPaymentFailed;
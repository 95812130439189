import React, { useEffect, useState } from 'react';
import { Container, Modal, Form, Button } from 'react-bootstrap';
import './plan.css';
import { useSelector, useDispatch } from 'react-redux';
import { DownArrow } from "../../assets/images";
const OrderSummery = (props) => {
    const product = useSelector(state => state.product.details)

    const [interval, setIntervals] = useState({
        count: 0,
        type: "month"
    })
    useEffect(() => {
        if (interval.count === 0) {
            if (product.priceObject.productObject.metadata.interval_overrided) {
                setIntervals({
                    count: product.priceObject.productObject.metadata.interval_overrided,
                    type: product.priceObject.productObject.metadata.interval_type_overrided
                })
            }
            else if (product.priceObject.recurring.interval_count) {
                setIntervals({
                    count: product.priceObject.recurring.interval_count,
                    type: product.priceObject.recurring.interval
                })
            }
            else {
                setIntervals({
                    count: 0,
                    type: "month"
                })
            }
        }
    }, interval)
    const [visible, setVisible] = useState(false);
    const [coupon, setCoupon] = useState("")

    return (
        <div className='order_summery'>
            <div className='ord_inner'>
                <h3>Order summary</h3>
                <div className='plan_summ'>
                    <div className='plan_left'>Plan</div>
                    <div className='plan_right'>{product.priceObject.productObject.name}</div>
                </div>
                <div className='plan_summ'>
                    <div className='plan_left'>Duration</div>
                    <div className='plan_right'>Until canceled</div>
                </div>
                {product.priceObject.recurring && product.priceObject.recurring.trial_period_days &&
                    <div className='plan_summ'>
                        <div className='plan_left'>Free trial</div>
                        <div className='plan_right'>{product.priceObject.recurring.trial_period_days} days</div>
                    </div>
                }
                <div className='coupon_code'><button type='button' className='coupon_but' onClick={() =>
                    (visible) ? setVisible(false) : setVisible(true)
                }>Enter a coupon code <img src={DownArrow} /></button>
                    {visible && <div><Form.Group className={"mb-3 " + props.validClass} controlId="exampleForm.ControlInput1">
                        <Form.Control type="text" placeholder="Enter a coupon code" onChange={(event) => { setCoupon(event.target.value) }} />
                    </Form.Group><button type="submit" disable className='apply_but' onClick={() => { props.handleApply(coupon) }}>Apply</button></div>}
                </div>
                <div className='total_amnt'>
                    <div className='text_l'>Total</div>
                    <div className='text_r'>${product.price}<span>{interval.count > 0 && "Every " + interval.count + " " + interval.type}</span></div>
                </div>
                {product.priceObject.recurring &&
                    product.priceObject.recurring.trial_period_days &&
                    <div className='freetrial'>
                        After the free trial, you will be charged {interval.type}ly until canceled.
                    </div>
                }
            </div>
            <div className='secure_check'>
                Secure Checkout
            </div>
        </div>
    );
};

export default OrderSummery;
import React from 'react';
//import "./styles.css";
// import {  Row, Col } from 'react-bootstrap';
import { Document,Page } from 'react-pdf/dist/esm/entry.webpack';
import LeftpanelMember from "../../../../components/leftpanel/leftpanel-member";
import HeaderPart from "../../../../components/layout/header/header";
import './shareable-images.css';
import {
    Trip,
    PDFFile,
} from "../../../../assets/images";


const WixTemplate = () => {
    return (
            <div>
                <HeaderPart />
                <div className='main_cont'>
                    <LeftpanelMember />
                    <div className='right_cont'>
                        <div className='right_banner_banner_cnt'>
                            <div className='event_heading'>
                                <h2>PDF Gallery</h2>
                            </div>
                            <div className='event_image_group'>
                                <div className='event_image_inner_group'>
                                    <div className='event_image_inner_box'>
                                        <Document file={PDFFile}>
                                            <Page pageNumber={1} />
                                        </Document>
                                        {/* <p>12.06.2023</p> */}
                                    </div>
                                </div>

                                <div className='event_image_inner_group'>
                                    <div className='event_image_inner_box'>
                                        <Document file={PDFFile}>
                                            <Page pageNumber={1} />
                                        </Document>
                                        {/* <p>12.06.2023</p> */}
                                    </div>
                                </div>

                                <div className='event_image_inner_group'>
                                    <div className='event_image_inner_box'>
                                        <Document file={PDFFile}>
                                            <Page pageNumber={1} />
                                        </Document>
                                        {/* <p>12.06.2023</p> */}
                                    </div>
                                </div>

                                <div className='event_image_inner_group'>
                                    <div className='event_image_inner_box'>
                                        <Document file={PDFFile}>
                                            <Page pageNumber={1} />
                                        </Document>
                                        {/* <p>12.06.2023</p> */}
                                    </div>
                                </div>

                                <div className='event_image_inner_group'>
                                    <div className='event_image_inner_box'>
                                        <Document file={PDFFile}>
                                            <Page pageNumber={1} />
                                        </Document>
                                        {/* <p>12.06.2023</p> */}
                                    </div>
                                </div>

                                <div className='event_image_inner_group'>
                                    <div className='event_image_inner_box'>
                                        <Document file={PDFFile}>
                                            <Page pageNumber={1} />
                                        </Document>
                                        {/* <p>12.06.2023</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        };

      export default WixTemplate;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success_page{
    padding: 50px 20px;
    width: 100%;
}

.success_inner_page{
    max-width: 700px;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 30px rgba(0,0,0,0.5);
    padding: 30px;
    border-radius: 10px;
    margin: 0 auto;
}
.paymn_success{
    text-align: center;
}
.paymn_success img{
    width: 150px;
}

.paymnt_text h2{
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: rgb(14, 223, 73);
    margin: 0;
}

.paymnt_text p{
    text-align: center;
    font-size: 22px;
    color: #000;
    font-weight: 500;
    margin: 20px 0;
}

.continue_but{
    margin-top: 30px;
    text-align: center;
}

.continue_but a{
    height: 40px;
    border-radius: 40px;
    border: 2px solid rgb(7, 136, 22);
    background: rgba(16, 193, 7, 0.3);
    color: #000;
    padding: 0 40px;
    line-height: 40px;
    display: inline-block;
    font-weight: 600;
}

.continue_but a:hover{
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/payment/success/success.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,cAAc;AAClB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,iCAAiC;IACjC,iCAAiC;IACjC,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".success_page{\n    padding: 50px 20px;\n    width: 100%;\n}\n\n.success_inner_page{\n    max-width: 700px;\n    width: 100%;\n    background: #fff;\n    box-shadow: 0 0 30px rgba(0,0,0,0.5);\n    padding: 30px;\n    border-radius: 10px;\n    margin: 0 auto;\n}\n.paymn_success{\n    text-align: center;\n}\n.paymn_success img{\n    width: 150px;\n}\n\n.paymnt_text h2{\n    text-align: center;\n    font-size: 32px;\n    font-weight: 600;\n    color: rgb(14, 223, 73);\n    margin: 0;\n}\n\n.paymnt_text p{\n    text-align: center;\n    font-size: 22px;\n    color: #000;\n    font-weight: 500;\n    margin: 20px 0;\n}\n\n.continue_but{\n    margin-top: 30px;\n    text-align: center;\n}\n\n.continue_but a{\n    height: 40px;\n    border-radius: 40px;\n    border: 2px solid rgb(7, 136, 22);\n    background: rgba(16, 193, 7, 0.3);\n    color: #000;\n    padding: 0 40px;\n    line-height: 40px;\n    display: inline-block;\n    font-weight: 600;\n}\n\n.continue_but a:hover{\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState , useEffect} from "react";
//import "./styles.css";
import { Row, Col, Form } from "react-bootstrap";

import "./affiliate-dashboard.css";
import {Redeem,} from "../../assets/images";

const Commissions = () => {
    return (

        <div className="wizard_inner refund">
            <div className="wizard_inner_inner ">
                <div className="wizard_overlay">
                    <h3>$ 1234.00 </h3>
                    <p>Total Commissions redeemed</p>
                    <img src={Redeem} />
                </div>
            </div>
        </div>
        );
    };
    export default Commissions;
import Axios from "axios";

const getBaseURL = () => {
  switch (process.env.ENV) {
    case "STAGING":
      return process.env.REACT_APP_API_URL_STAGING;

    case "DEVELOPEMENT":
      return process.env.REACT_APP_API_URL_LOCAL;

    default:
      return process.env.REACT_APP_API_URL_STAGING;
  }
};
const axios = Axios.create({
  baseURL: getBaseURL()
});

export default axios;

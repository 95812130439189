// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event_image_group{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.event_image_inner_group{
    max-width: calc(100%/4);
    width: 100%;
    margin-bottom: 25px;
    padding: 0 10px;
}

.event_image_inner_box{
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    height: 250px;
    border-radius: 10px;
    border: 1px solid #ccc;
    overflow: hidden;
}

.event_image_inner_box img{
    height: 200px;
    object-fit: cover;
    width: 100%;
}

.event_heading{
    margin-bottom: 50px;
}

.event_heading h2{
    font-size: 30px;
}

.event_image_inner_box p{
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
}

@media screen and (max-width:1199px){

    .event_image_inner_group{
        max-width: calc(100%/3);
    }
}

@media screen and (max-width:991px){

    .event_image_inner_group{
        max-width: calc(100%/2);
    }
}

@media screen and (max-width:480px){

    .event_image_inner_group{
        max-width: calc(100%/1);
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/calendar-of-events/events.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,WAAW;IACX,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;;IAEI;QACI,uBAAuB;IAC3B;AACJ;;AAEA;;IAEI;QACI,uBAAuB;IAC3B;AACJ;;AAEA;;IAEI;QACI,uBAAuB;IAC3B;AACJ","sourcesContent":[".event_image_group{\n    display: flex;\n    flex-wrap: wrap;\n    margin: 0 -10px;\n}\n\n.event_image_inner_group{\n    max-width: calc(100%/4);\n    width: 100%;\n    margin-bottom: 25px;\n    padding: 0 10px;\n}\n\n.event_image_inner_box{\n    box-shadow: 0 0 10px rgba(0,0,0,0.5);\n    height: 250px;\n    border-radius: 10px;\n    border: 1px solid #ccc;\n    overflow: hidden;\n}\n\n.event_image_inner_box img{\n    height: 200px;\n    object-fit: cover;\n    width: 100%;\n}\n\n.event_heading{\n    margin-bottom: 50px;\n}\n\n.event_heading h2{\n    font-size: 30px;\n}\n\n.event_image_inner_box p{\n    font-size: 16px;\n    font-weight: 600;\n    text-align: center;\n    padding: 10px;\n}\n\n@media screen and (max-width:1199px){\n\n    .event_image_inner_group{\n        max-width: calc(100%/3);\n    }\n}\n\n@media screen and (max-width:991px){\n\n    .event_image_inner_group{\n        max-width: calc(100%/2);\n    }\n}\n\n@media screen and (max-width:480px){\n\n    .event_image_inner_group{\n        max-width: calc(100%/1);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import axios from "./axios";

export const signUp = (data) => {
  return new Promise((resolve, reject) => {
    axios.post("/user/create-temp-user", data)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
export const additionInfo = (data) => {
  return new Promise((resolve, reject) => {
    axios.post("/user/update-temp-user/" + data.id, data)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
export const createPayment = (data) => {
  return new Promise((resolve, reject) => {
    data.description = "Description";
    axios.post("/user/create-payment", data)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error.message)
      })
  })
}
export const increaseHits = (code) => {
  return new Promise((resolve, reject) => {
    axios.post("/affiliate/increase-hits/" + code + "/tvi/tvi")
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error.message)
      })
  })
}
export const updatePlanPayment = (data) => {
  return new Promise((resolve, reject) => {
    data.description = "Description";
    axios.post("/user/update-plan-payment", data)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error.message)
      })
  })
}

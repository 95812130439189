// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right_innrBanner:last-child .banner_overlay{}
.right_inner_inner_banner{
    position: relative;
}


.banner1_box{
    position: relative;
}

.banner1_box.travel_boxxx:before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.4);
}

.banner1_box.travel_boxxx .banner1_overlay{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    background: rgba(255, 255, 255, 0.7);
    padding: 10px;
    font-size: 38px;
    color: #111 !important;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    bottom: auto;
}

.banner1_box.travel_boxxx{
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    height: 350px;
    position: relative;
    z-index: 1;
    display: block;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/training-video-dashboard/training-video-dashboard.css"],"names":[],"mappings":"AAAA,6CAA6C;AAC7C;IACI,kBAAkB;AACtB;;;AAGA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,MAAM;IACN,SAAS;IACT,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,2BAA2B;IAC3B,WAAW;IACX,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,cAAc;IACd,uCAAuC;AAC3C","sourcesContent":[".right_innrBanner:last-child .banner_overlay{}\n.right_inner_inner_banner{\n    position: relative;\n}\n\n\n.banner1_box{\n    position: relative;\n}\n\n.banner1_box.travel_boxxx:before{\n    content: \"\";\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    background: rgba(0,0,0,0.4);\n}\n\n.banner1_box.travel_boxxx .banner1_overlay{\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    width: 100%;\n    left: 0;\n    right: 0;\n    margin: auto;\n    background: rgba(255, 255, 255, 0.7);\n    padding: 10px;\n    font-size: 38px;\n    color: #111 !important;\n    font-weight: 500;\n    text-transform: uppercase;\n    text-align: center;\n    bottom: auto;\n}\n\n.banner1_box.travel_boxxx{\n    width: 100%;\n    border-radius: 10px;\n    overflow: hidden;\n    height: 350px;\n    position: relative;\n    z-index: 1;\n    display: block;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

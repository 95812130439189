// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/footer_bg.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq_cnt_area {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center center / cover
    no-repeat fixed;
  padding-bottom: 3rem;
}
.faq_cnt {
  padding: 3rem 6rem;
  background-color: rgb(255 255 255 / 78%);
}
.faq_title h2 {
  font-size: 2.125rem;
  font-family: "Barlow", sans-serif !important;
  font-weight: 300;
}

.faq_item h2 {
  font-size: 1.563rem;
  font-family: "Barlow", sans-serif !important;
}
.faq_item {
  margin-bottom: 2rem;
}

@media screen and (max-width: 767px) {
  .faq_cnt {
    padding: 2rem 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/faq/faq.css"],"names":[],"mappings":"AAAA;EACE;mBACiB;EACjB,oBAAoB;AACtB;AACA;EACE,kBAAkB;EAClB,wCAAwC;AAC1C;AACA;EACE,mBAAmB;EACnB,4CAA4C;EAC5C,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,4CAA4C;AAC9C;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".faq_cnt_area {\n  background: url(\"../../assets/images/footer_bg.webp\") center center / cover\n    no-repeat fixed;\n  padding-bottom: 3rem;\n}\n.faq_cnt {\n  padding: 3rem 6rem;\n  background-color: rgb(255 255 255 / 78%);\n}\n.faq_title h2 {\n  font-size: 2.125rem;\n  font-family: \"Barlow\", sans-serif !important;\n  font-weight: 300;\n}\n\n.faq_item h2 {\n  font-size: 1.563rem;\n  font-family: \"Barlow\", sans-serif !important;\n}\n.faq_item {\n  margin-bottom: 2rem;\n}\n\n@media screen and (max-width: 767px) {\n  .faq_cnt {\n    padding: 2rem 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import LeftpanelMember from "../../../components/leftpanel/leftpanel-member";
import HeaderPart from "../../../components/layout/header/header";
import "./embed-code-page.css";
import axios from "../../../axios/axios";
import { message } from "antd";
import { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { copyIcon } from "../../../assets/images";
import copy from "copy-to-clipboard";

const TravelDashboardPart = () => {
  const { origin } = window.location;

  const [allCertificate, setAllCertificates] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllCertificate();
  }, []);

  const getAllCertificate = () => {
    setLoading(true);

    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("AuthToken")}` },
    };

    axios
      .get("/all-certificates", config)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          let userDetails = localStorage.getItem("userDetails");

          userDetails = userDetails ? JSON.parse(userDetails) : {};
          let certificates = res.data.data.map((item) => {
            console.log(`<iframe  src="${origin}/customer-facing/${userDetails?._id}/${item.certificateId}/velocity" title="${item.certificateTitle}" style="border:0px #ffffff none; " name="myiFrame" scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px" height="350px" width="100%" allowfullscreen  ></iframe>`);
            return {
              certificateId: item.certificateId,
              title: item.certificateTitle,
              embed_code: ` <iframe  src="${origin}/customer-facing/${userDetails?._id}/${item.certificateId}" title="${item.certificateTitle}" style="border:0px #ffffff none; " name="myiFrame" scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px" height="350px" width="100%" allowfullscreen  ></iframe>`,
            };
          });
          setAllCertificates(certificates);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        message.error("Something went wrong!!");
      });
  };

  return (
    <LoadingOverlay active={loading} spinner text="loading...">
      <HeaderPart />
      <div className="main_cont">
        <LeftpanelMember />
        <div className="right_cont">
          <div className="right_banner_banner_cnt">
            <div className="affiliate_facing">
              <h2>Embed Codes</h2>
              <p>
                To embed the "Send Certificate" tool on another website enter
                this code
              </p>
            </div>

            {allCertificate.map((certificate, key) => {
              if (certificate.certificateId != "SFX5NIGHT" && certificate.certificateId != "SFX7NIGHT") {
                return (
                  <div className="embed_code_inner" key={key}>
                    <h3>{certificate.title} embed code</h3>
                    <div className="label_box">
                      <label>{certificate.embed_code}</label>
                      <img
                        onClick={() => {
                          copy(certificate.embed_code);
                          message.success("Copied!");
                        }}
                        className="copy"
                        src={copyIcon}
                        alt="Copy"
                      />
                    </div>
                    {/* <div
                    dangerouslySetInnerHTML={{
                      __html: certificate.embed_code,
                    }}
                  /> */}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default TravelDashboardPart;

import React, { useState, useEffect } from "react";
//import "./styles.css";
import { Row, Col, Form, Dropdown } from "react-bootstrap";

import "./affiliate-dashboard.css";
import { ClickImg, } from "../../assets/images";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';
import { useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const Chart = (props) => {
  const [showMsg, setshowMsg] = useState(false)


  const copyToClipBoard = (content) => {
    var clipboard = navigator.clipboard;
    if (clipboard == undefined) {
      console.log('copy undefined');
    } else {
      clipboard.writeText(content);
      document.execCommand("copy");
      setshowMsg(true)

      setTimeout(() => {
        setshowMsg(false)
      }, 3000);
    }
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        //position: 'top',
      },
      title: {
        display: true,
        text: 'Commission Chart',
      },
    },
  };
  const location = useLocation();
  console.log(process.env.REACT_APP_TV_APP_URL);
  let tvAfflliateUrlString = (props.AffiliateUrl) ? props.AffiliateUrl.substring(props.AffiliateUrl.lastIndexOf('/') + 1) : "";
  let tvAfflliateUrlMake = process.env.REACT_APP_TV_APP_URL + 'refer/tvi/' + tvAfflliateUrlString;
  let replaceUrl = props.AffiliateUrl;
  if (replaceUrl.includes('affiliate')) {
    replaceUrl = replaceUrl.replace('affiliate', 'refer');
  }
  return (

    <div className="chart_partts">
      <div className="chart_heading ">
        <h3 className="text-center">TripValet Incentives Affiliate Link</h3>
        <div className="d-flex">
          <div className="border_earned shadow-sm mb-5 ml-3"><p className="p-2 pl-3 text-center">{replaceUrl}</p></div>
          <button type="button" className="copy_but" onClick={() => copyToClipBoard(replaceUrl)}>Copy</button>

        </div>
        <div className="text-center row mb-5">
          <div className="col-md-1"></div>
          <div className="col-md-3"><h5>Share the link on :</h5></div>
          <div className="col-md-1">
            <FacebookShareButton url={replaceUrl} title="share on Facebook">
              <FacebookIcon size={35} round={true}></FacebookIcon>
            </FacebookShareButton>
          </div>
          <div className="col-md-1">
            <LinkedinShareButton url={replaceUrl} title="share on Linkedin">
              <LinkedinIcon size={35} round={true}></LinkedinIcon>
            </LinkedinShareButton>
          </div>
          <div className="col-md-1">
            <TelegramShareButton url={replaceUrl} title="share on Telegram">
              <TelegramIcon size={35} round={true}></TelegramIcon>
            </TelegramShareButton>
          </div>
          <div className="col-md-1">
            <TwitterShareButton url={replaceUrl} title="share on Twitter">
              <TwitterIcon size={35} round={true}></TwitterIcon>
            </TwitterShareButton>
          </div>
          <div className="col-md-4"></div>
        </div>
        <h3 className="text-center">TripValet Affiliate Link</h3>
        <div className="d-flex">
          <div className="border_earned shadow-sm mb-5 ml-3"><p className="p-2 pl-3 text-center">{tvAfflliateUrlMake}</p></div>
          <button type="button" className="copy_but" onClick={() => copyToClipBoard(tvAfflliateUrlMake)}>Copy</button>


        </div>
        <div className="text-center row mb-5">
          <div className="col-md-1"></div>
          <div className="col-md-3"><h5>Share the link on :</h5></div>
          <div className="col-md-1">
            <FacebookShareButton url={tvAfflliateUrlMake} title="share on Facebook">
              <FacebookIcon size={35} round={true}></FacebookIcon>
            </FacebookShareButton>
          </div>
          <div className="col-md-1">
            <LinkedinShareButton url={tvAfflliateUrlMake} title="share on Linkedin">
              <LinkedinIcon size={35} round={true}></LinkedinIcon>
            </LinkedinShareButton>
          </div>
          <div className="col-md-1">
            <TelegramShareButton url={tvAfflliateUrlMake} title="share on Telegram">
              <TelegramIcon size={35} round={true}></TelegramIcon>
            </TelegramShareButton>
          </div>
          <div className="col-md-1">
            <TwitterShareButton url={tvAfflliateUrlMake} title="share on Twitter">
              <TwitterIcon size={35} round={true}></TwitterIcon>
            </TwitterShareButton>
          </div>
          <div className="col-md-4"></div>
        </div>
        {showMsg ? (<>
          <p className="text-center text-success"><b>Copied!</b></p>
        </>) : (<></>)}

        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="earned_amnt_chhhartt">
            <h2>Commission Chart</h2>
          </div>
          <div className="d-flex flex-wrap date__form">
            <Form.Group className="mx-3 md-mb-3" controlId="formBasicEmail">
              <Form.Label>From Date</Form.Label>
              <Form.Control type="date" placeholder="Enter date " name="fromDate" onChange={(event) => { props.handleChange(event) }} />
            </Form.Group>
            <Form.Group className="mx-3" controlId="formBasicEmail">
              <Form.Label>To Date</Form.Label>
              <Form.Control type="date" placeholder="Enter date" name="toDate" onChange={(event) => { props.handleChange(event) }} />
            </Form.Group>
          </div>
        </div>
      </div>
      <Bar options={options} data={props.chartData} />
    </div>
  );
};
export default Chart;
export const changeStep = (step) => {
    return {
        type: "CHANGE",
        payload: step
    }
}
export const setPlan = (plan) => {
    return {
        type: "CHANGE",
        payload: plan
    }
}
export const setTempUser = (user) => {
    return {
        type: "SETUSER",
        payload: user
    }
}
export const setPayment = (data) => {
    return {
        type: "SETPAYMENT",
        payload: data
    }
}
export const setLogin = (data) => {
    return {
        type: "SETLOGIN",
        payload: data
    }
}

export const setCode = (code) => {
    return {
        type: "SETCODE",
        payload: code
    }
}

export const setSuccessCode = (data) => {
    return {
        type: "SETSUCCESSCODE",
        payload: data
    }
}

export const setAffiliateUserIdForLeadGenerate = (data) => {
    return {
        type: "SETAFFILIATEUSERID",
        payload: data
    }
}


import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import "./augeo-payment.css";
import "react-datepicker/dist/react-datepicker.css";
import { Logo } from "../../assets/images";
import { useParams, useHistory } from "react-router-dom";
import axios from "../../axios/axios";
import { message } from "antd";
import LoadingOverlay from "react-loading-overlay";
import useFormValidation from "../../hooks/useFormValidator";
import { Buffer } from "buffer";

const AugeoPaymentPage = () => {
    const navigate = useHistory();
    const { email, sender_id, rec_name, source_domain } = useParams();
    const { req } = useParams();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [certi, setCerti] = useState({});
    const [parseDataAttr, setparseDataAttr] = useState({});
    const [priceDetails, setpriceDetails] = useState({});
    const [certiAvlb, setCertiAvlb] = useState({});
    const [certiId, setcertiId] = useState("");
    const [card, setCard] = useState({
        number: "",
        exp_month: "",
        exp_year: "",
        cvc: "",
        name_on_card: "",
    });
    const [validationReport, validate] = useFormValidation();

    const makePayment = async (e) => {
        e.preventDefault();
        setLoading2(true);

        let data = {
            parseDataAttr,
            card,
            certiId
        };

        await axios
            .post(
                `/augeo-payment`,
                data

                // {
                //   headers: {
                //     Authorization: token,
                //   },
                // }
            )
            .then((res) => {
                setLoading2(false);
                console.log(res);
                if (res.data.status) {
                    message.success(res.data.message);
                    setCard({
                        number: "",
                        exp_month: "",
                        exp_year: "",
                        cvc: "",
                        name_on_card: "",
                    });
                    navigate.push(`/augeo-thank-you?url=${res.data.activeLink}`);
                } else {
                    message.error(res.data.message);
                    setCard({
                        number: "",
                        exp_month: "",
                        exp_year: "",
                        cvc: "",
                        name_on_card: "",
                    })
                }
            })
            .catch((err) => {
                console.log("err", err);
                setLoading(false);

            });
    };
    const getCertiDetails = async (certiId) => {
        setLoading(true);
        let data = { certiId };

        await axios
            .post(
                `/get-certificates/`,
                data

                // {
                //   headers: {
                //     Authorization: token,
                //   },
                // }
            )
            .then((res) => {
                if (res.data.status) {
                    setLoading(false);
                    setCertiAvlb(res.data.status);
                    setCerti(res.data.data);
                    // message.success("success");
                } else {
                    setLoading(false);
                    setCertiAvlb(res.data.status);
                    message.error("sorry..!!certificate not found");
                    // setCerti(res.data.data);
                }
            })
            .catch((err) => {
                console.log("err", err);
                setLoading(false);
            });
    };
    const getPriceDetails = async (priceId) => {
        setLoading(true);


        await axios
            .get(
                `/user/get-id-by-augeo-price/${priceId}`)
            .then((res) => {
                setLoading(false);
                if (res.data.status) {
                    setpriceDetails(res.data.data);
                }
            })
            .catch((err) => {
                console.log("err", err);
                setLoading(false);
            });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        const buffer = Buffer.from(req, "base64");
        const originalValue = buffer.toString("utf-8");
        let parseData = JSON.parse(originalValue);
        setcertiId(parseData.certificate_id)
        getCertiDetails(parseData.certificate_id);
        setparseDataAttr(parseData);
        getPriceDetails(parseData.plan);
    }, []);

    const handleCardOnChange = (e) => {
        let { name, value } = e.target;
        card[name] = value;
        let rules = JSON.parse(e.target.dataset.rules);
        validate(value, rules, name);
        setCard({ ...card });
    };

    return (
        <LoadingOverlay
            active={loading}
            spinner
            className="pay_loader"
            text="please Wait,Dont Refresh Page ..."
        >
            <div className="main_cont stripe_cont">
                <LoadingOverlay
                    active={loading2}
                    spinner
                    className="pay_loader2"
                    text="Payment is being Proceeded,Dont Refresh Page ..."
                >
                    <div className="paymnt_box">
                        <div className="pay_logo">
                            <img src={Logo} alt="" />
                        </div>
                        <div className="paymnt_partts">
                            <div className="paymnt_inner">
                                <div className="pay_card">
                                    <h2>Pay with card</h2>
                                    <div className="package_details">
                                        <label>
                                            Certificate Name:<span>{certi.certificateTitle}</span>
                                        </label>
                                        <label>
                                            Total Amount:<span>${priceDetails.defaultPrice}</span>
                                        </label>
                                    </div>
                                    <div className="payment_inner_box">
                                        <Form onSubmit={makePayment}>
                                            <Form.Group
                                                className="mb-3 field_partts"
                                                controlId="formBasicEmail"
                                            >
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Name"
                                                    className="login_field"
                                                    value={card.name_on_card}
                                                    onChange={(e) =>
                                                        setCard({
                                                            ...card,
                                                            name_on_card: e.target.value,
                                                        })
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                            <Row>
                                                <Col lg="12" md="12">
                                                    <Form.Group
                                                        className="mb-3 field_partts"
                                                        controlId="formBasicPassword"
                                                    >
                                                        <Form.Label>Card Number</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="number"
                                                            placeholder="Card Number"
                                                            className="login_field"
                                                            value={card.number}
                                                            data-rules={JSON.stringify({
                                                                required: true,
                                                                creditcard: true,
                                                            })}
                                                            required
                                                            onChange={handleCardOnChange}
                                                        />
                                                        {validationReport.number &&
                                                            !validationReport.number.isValid ? (
                                                            <small className="mt-1 text-danger">
                                                                {validationReport.number.errorMessage}
                                                            </small>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col lg="6" md="6">
                                                    <Form.Group
                                                        className="mb-3 field_partts"
                                                        controlId="formBasicPassword"
                                                    >
                                                        <Form.Label>Expiry Date</Form.Label>

                                                        <Row>
                                                            <Col lg="4" md="5">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="MM"
                                                                    name="exp_month"
                                                                    className="login_field"
                                                                    value={card.exp_month}
                                                                    data-rules={JSON.stringify({
                                                                        required: true,
                                                                        month: true,
                                                                    })}
                                                                    required
                                                                    onChange={handleCardOnChange}
                                                                />
                                                                {validationReport.exp_month &&
                                                                    !validationReport.exp_month.isValid ? (
                                                                    <small className="mt-1 text-danger">
                                                                        {validationReport.exp_month.errorMessage}
                                                                    </small>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Col>
                                                            <Col lg="8" md="7">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="YYYY"
                                                                    className="login_field"
                                                                    value={card.exp_year}
                                                                    name="exp_year"
                                                                    onChange={handleCardOnChange}
                                                                    data-rules={JSON.stringify({
                                                                        required: true,
                                                                        currentYear: true,
                                                                    })}
                                                                    required
                                                                />
                                                                {validationReport.exp_year &&
                                                                    !validationReport.exp_year.isValid ? (
                                                                    <small className="mt-1 text-danger">
                                                                        {validationReport.exp_year.errorMessage}
                                                                    </small>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        <div className="select_box"></div>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg="6" md="6">
                                                    <Form.Group
                                                        className="mb-3 field_partts"
                                                        controlId="formBasicPassword"
                                                    >
                                                        <Form.Label>CVC</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="CVC"
                                                            name="cvc"
                                                            className="login_field"
                                                            value={card.cvc}
                                                            data-rules={JSON.stringify({
                                                                required: true,
                                                                cvv: true,
                                                            })}
                                                            required
                                                            onChange={handleCardOnChange}
                                                        />
                                                        {validationReport.cvc &&
                                                            !validationReport.cvc.isValid ? (
                                                            <small className="mt-1 text-danger">
                                                                {validationReport.cvc.errorMessage}
                                                            </small>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <div className="cvc_number">
                                                            <svg
                                                                className="p-CardCvcIcons-svg"
                                                                width="40"
                                                                height="40"
                                                                viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="var(--colorIconCardCvc)"
                                                                role="presentation"
                                                            >
                                                                <path
                                                                    opacity=".2"
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M15.337 4A5.493 5.493 0 0013 8.5c0 1.33.472 2.55 1.257 3.5H4a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1v-.6a5.526 5.526 0 002-1.737V18a2 2 0 01-2 2H3a2 2 0 01-2-2V6a2 2 0 012-2h12.337zm6.707.293c.239.202.46.424.662.663a2.01 2.01 0 00-.662-.663z"
                                                                />
                                                                <path
                                                                    opacity=".4"
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M13.6 6a5.477 5.477 0 00-.578 3H1V6h12.6z"
                                                                />
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M18.5 14a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm-2.184-7.779h-.621l-1.516.77v.786l1.202-.628v3.63h.943V6.22h-.008zm1.807.629c.448 0 .762.251.762.613 0 .393-.37.668-.904.668h-.235v.668h.283c.565 0 .95.282.95.691 0 .393-.377.66-.911.66-.393 0-.786-.126-1.194-.37v.786c.44.189.88.291 1.312.291 1.029 0 1.736-.526 1.736-1.288 0-.535-.33-.967-.88-1.14.472-.157.778-.573.778-1.045 0-.738-.652-1.241-1.595-1.241a3.143 3.143 0 00-1.234.267v.77c.378-.212.763-.33 1.132-.33zm3.394 1.713c.574 0 .974.338.974.778 0 .463-.4.785-.974.785-.346 0-.707-.11-1.076-.337v.809c.385.173.778.26 1.163.26.204 0 .392-.032.573-.08a4.313 4.313 0 00.644-2.262l-.015-.33a1.807 1.807 0 00-.967-.252 3 3 0 00-.448.032V6.944h1.132a4.423 4.423 0 00-.362-.723h-1.587v2.475a3.9 3.9 0 01.943-.133z"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <div className="pay_button">
                                                {certiAvlb ? (
                                                    <Button
                                                        variant="primary"
                                                        type="submit"
                                                        className="pay_but"
                                                    >
                                                        Pay
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        className="pay_but"
                                                    >
                                                        No Certificate to Pay
                                                    </Button>
                                                )}
                                            </div>
                                            <div className="mt-2 text-white">
                                                <b className="mr-2">Note:</b>
                                                <span className="text-justify">
                                                    To avoid being charged multiple times, do NOT hit the
                                                    PAY button more than once or the back button.
                                                    Unfortunately, if you accidentally activate multiple
                                                    certificates we are not able to refund multiple
                                                    charges.
                                                </span>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        </LoadingOverlay>
    );
};

export default AugeoPaymentPage;

import { combineReducers } from "redux";
import changeStep from "./signUp.reducer";
import product from "./product.reducer"


const rootReducers = combineReducers(
    {
        changeStep,
        product
    }
)
export default rootReducers
import React, { useEffect, useState } from 'react';
//import "./styles.css";
import { Container, Modal, Form, Button } from 'react-bootstrap';

import './plan.css';
import AdditionalInfo from "./additional-info";
import useFormValidation from '../../hooks/useFormValidator';
import axios from 'axios';
import { signUp } from '../../axios/signUp.axios';
import { useSelector, useDispatch } from 'react-redux';
import { setTempUser } from '../../actions/signUp.actions';
import { message } from "antd";
import { NotificationContainer } from 'react-notifications';
import { Link } from 'react-router-dom';
import { products } from '../../products/products.details';
import { getPrice } from '../../axios/payment.axios';
import { payments } from '../../actions/payment.actions';
import { useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
const OrderCheckoutSignUp = (props) => {
    // const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const code = useSelector(state => state.changeStep.affiliate)

    const [input, setInput] = useState({
        email: "",
        password: "",
        affiliate: "",
        package_name: "",
        isOrderCheckoutUser: true
    })

    const [validationReport, validate] = useFormValidation()
    const [buttonStatus, setButtonStatus] = useState(true)


    const change = async (wizard) => {
        if (validationReport.email && validationReport.email.isValid
            && validationReport.password && validationReport.password.isValid && validationReport.package_name) {
            props.loader(true)
            await signUp(input)
                .then((response) => {
                    // console.log(response,"res")
                    dispatch(setTempUser(response.data.data))
                    props.changeWizard(wizard)
                    props.loader(false)

                })
                .catch(err => {

                    //    alert(err.response.data.message)
                    props.notify(err.response.data.message, false)
                    props.loader(false)
                })

        }
        else {

        }


    }

    const handleChange = (event) => {

        let rules = JSON.parse(event.target.dataset.rules)

        validate(event.target.value, rules, event.target.name)
        if (event.target.name == 'email') {
            setInput({ ...input, [event.target.name]: event.target.value.toLowerCase() })
        } else {
            setInput({ ...input, [event.target.name]: event.target.value })
        }

    }

    function log() {
        console.log("report", validationReport)
    }

    useEffect(() => {
        if (code)
            setInput({
                affiliate: code
            })
    }, code)









    return (

        <div className='sign_up_partts'><NotificationContainer />
            <h3>1. Sign Up</h3>
            <p>To purchase this plan and use its benefits in the future, log in to your account or sign up.</p>

            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        value={input.email}
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                        className='sign_email'
                        data-rules={JSON.stringify({
                            required: true,
                            email: true
                        })

                        }
                        onChange={(event) => { handleChange(event) }}
                    />
                    {validationReport.email && !validationReport.email.isValid ? <small className='text-danger'>{validationReport.email.errorMessage} </small> : ""}
                    {log()}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        value={input.password}
                        name="password"
                        type="password"
                        placeholder="Password"
                        className='sign_email'
                        data-rules={
                            JSON.stringify({
                                required: true,
                                password: true
                            })
                        }
                        onChange={(event) => { handleChange(event) }}
                    />
                    {validationReport.password && !validationReport.password.isValid ? <small className='text-danger'>{validationReport.password.errorMessage} </small> : ""}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Choose Product</Form.Label>
                    <Form.Select
                        name="package_name"
                        className='form-control'
                        data-rules={
                            JSON.stringify({
                                required: true,
                                package: true
                            })
                        }
                        required
                        onChange={(event) => {
                            handleChange(event);
                            if (event.target.value) {
                                props.setProduct(event);
                            }
                        }}>
                        <option value="">Select Product</option>
                        <option data-price={products.tvi[0].price} data-product_id={products.tvi[0].id} data-name={products.tvi[0].name} value={products.tvi[0].id}>Monthly $97</option>
                        <option data-price={products.tvi[1].price} data-product_id={products.tvi[1].id} data-name={products.tvi[1].name} value={products.tvi[1].id}>3 Months $257</option>
                        <option data-price={products.tvi[2].price} data-product_id={products.tvi[2].id} data-name={products.tvi[2].name} value={products.tvi[2].id}>6 Months $497</option>
                        {/* <option data-price={products.tvi[4].price} data-product_id={products.tvi[4].id} data-name={products.tvi[4].name} value="TVI12">TVI 12 Month Membership $947</option> */}
                        <option data-price={products.tvi[3].price} data-product_id={products.tvi[3].id} data-name={products.tvi[3].name} value={products.tvi[3].id}>TVI 12 Month Membership $947</option>

                    </Form.Select>
                    {validationReport.package && !validationReport.package.isValid ? <small className='text-danger'>{validationReport.package.errorMessage} </small> : ""}
                </Form.Group>


                <div className='sign_submit flex-wrap justify-content-between align-items-center'>
                    <p className='mb-0'>Already have an account? <Link to="/login" >Login</Link></p>
                    {/* {show && <AdditionalInfo />} */}
                    <Button variant="primary" type="button" onClick={() => { change("aInfo") }} disabled={!buttonStatus}>
                        Next
                    </Button>

                </div>
            </Form>
        </div>




    );
};

export default OrderCheckoutSignUp;
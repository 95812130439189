// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
        margin: 0;
        padding: 0;
        background-color: #FAFAFA;
        /* font: 12pt "Tahoma"; */
    }
    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    .pdf_inner_inner_box1 {
        width: 21cm;
        min-height: 29.7cm;
        padding: 1cm;
        margin: 1cm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
    
    @page {
        size: A4;
        margin: 0;
        size: portrait;
    }
    @media print {
        @page {
            size: A4;
            margin: 0;
            size: portrait;
        }
        .pdf_inner_inner_box1 {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
            size: landscape;
        }
    }





    .form-box{
        width: 21cm;
        padding: 1cm;
        margin: 1cm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }`, "",{"version":3,"sources":["webpack://./src/pages/travelcertificates/print/download-print.css"],"names":[],"mappings":"AAAA;QACQ,SAAS;QACT,UAAU;QACV,yBAAyB;QACzB,yBAAyB;IAC7B;IACA;QACI,sBAAsB;QACtB,2BAA2B;IAC/B;IACA;QACI,WAAW;QACX,kBAAkB;QAClB,YAAY;QACZ,gBAAgB;QAChB,yBAAyB;QACzB,kBAAkB;QAClB,iBAAiB;QACjB,sCAAsC;IAC1C;;IAEA;QACI,QAAQ;QACR,SAAS;QACT,cAAc;IAClB;IACA;QACI;YACI,QAAQ;YACR,SAAS;YACT,cAAc;QAClB;QACA;YACI,SAAS;YACT,eAAe;YACf,sBAAsB;YACtB,cAAc;YACd,mBAAmB;YACnB,mBAAmB;YACnB,mBAAmB;YACnB,wBAAwB;YACxB,eAAe;QACnB;IACJ;;;;;;IAMA;QACI,WAAW;QACX,YAAY;QACZ,gBAAgB;QAChB,yBAAyB;QACzB,kBAAkB;QAClB,iBAAiB;QACjB,sCAAsC;IAC1C","sourcesContent":["body {\n        margin: 0;\n        padding: 0;\n        background-color: #FAFAFA;\n        /* font: 12pt \"Tahoma\"; */\n    }\n    * {\n        box-sizing: border-box;\n        -moz-box-sizing: border-box;\n    }\n    .pdf_inner_inner_box1 {\n        width: 21cm;\n        min-height: 29.7cm;\n        padding: 1cm;\n        margin: 1cm auto;\n        border: 1px #D3D3D3 solid;\n        border-radius: 5px;\n        background: white;\n        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);\n    }\n    \n    @page {\n        size: A4;\n        margin: 0;\n        size: portrait;\n    }\n    @media print {\n        @page {\n            size: A4;\n            margin: 0;\n            size: portrait;\n        }\n        .pdf_inner_inner_box1 {\n            margin: 0;\n            border: initial;\n            border-radius: initial;\n            width: initial;\n            min-height: initial;\n            box-shadow: initial;\n            background: initial;\n            page-break-after: always;\n            size: landscape;\n        }\n    }\n\n\n\n\n\n    .form-box{\n        width: 21cm;\n        padding: 1cm;\n        margin: 1cm auto;\n        border: 1px #D3D3D3 solid;\n        border-radius: 5px;\n        background: white;\n        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

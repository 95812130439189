import React from "react";
import {
    Button,
    Form,
  } from "react-bootstrap";

  import {
    Certificatebanner,
    TviWLogo,
} from "../../assets/images";
  import "./tvi-redeem.css";


  const TviRedeem = () => {
    return (
        <section className="tvi_redeem">
            <div className="redeem_left">
                <div className="redeem_left_inner">
                <Form>
                    <Form.Group className="mb-4 redeem__form" controlId="formBasicEmail"> 
                        <Form.Control type="text" placeholder="Enter name" className="redeem__field"/>
                        <Form.Label>Your Name</Form.Label>
                    </Form.Group>

                    <Form.Group className="mb-4 redeem__form" controlId="formBasicEmail"> 
                        <Form.Control type="email" placeholder="Enter email"  className="redeem__field"/>
                        <Form.Label>Your Email</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-4 redeem__form" controlId="formBasicEmail"> 
                        <Form.Control type="text" placeholder="Enter referral code" className="redeem__field"/>
                        <Form.Label>Referral Code</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-4 redeem__form" controlId="formBasicEmail"> 
                        <Form.Control type="text" placeholder="Enter certificate code" className="redeem__field"/>
                        <Form.Label>Certificate Code</Form.Label>
                    </Form.Group>
                    <div className="redeem_submit">
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </div>
                </Form>
                </div>
            </div>
            <div className="redeem_right">
                <div className="redeem_banner"><img src={Certificatebanner} /></div>
                <div className="redeem_top_text">
                    <img src={TviWLogo} />
                    <div className="cert_trav">
                        <h2>Ready to use your travel certificate?</h2>
                        <p>Choose from thousands of worldwide destinations.</p>
                        
                    </div>
                    <h3>" Travel More, <span>Pay Less! "</span></h3>
                </div>
                
            </div>
        </section>
        );
    };
        export default TviRedeem;
import React, { useEffect, useState } from "react";
import { Container, Dropdown, Button, Modal } from "react-bootstrap";
import "./header.css";
import { Logo, Profile_Icon } from "../../../assets/images";
import {checkUser} from "../../../axios/login.axios"
import { message } from "antd";
const Header = () => {
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(
    localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))
      : null
  );
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const logout = async () => {                     
    localStorage.clear();
    message.warning("You are being logged out. Please wait...");
    window.open("/logout", "_self");
  };
  useEffect(()=>{
   if(user){
    checkUser(user.id)
    .then(resp => {
      console.log("resss,resp",resp.data)
      if(!resp.data.status)
      logout()
    })
    .catch(err => {
      message.warning("something went wrong. Please wait...");
    })
   }
  },[user])
  return (
    <div className="header_partttss">
      <Container fluid>
        <div className="header_inner">
          <div className="header_logo">
            <a href="/">
              <img src={Logo} alt="" />
            </a>
          </div>
          <div className="header_user">
            <span>Welcome, {user ? user.name : ""}!</span>
            <div className="prf_iconnn">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={Profile_Icon} alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1" onClick={handleShow}>
                    Log Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="modal_close">
            <h3>Are you sure you want to logout?</h3>
            <Button
              variant="secondary"
              onClick={handleClose}
              className="close_buuut"
            ></Button>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer_but">
          <Button variant="secondary" onClick={logout} className="yesbut">
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose} className="nobut">
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Header;

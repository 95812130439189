import React, { useState } from 'react';
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import './plan.css';
import useFormValidation from '../../hooks/useFormValidator';

import { createPayment, updatePlanPayment } from '../../axios/signUp.axios';
import { useSelector, useDispatch } from 'react-redux';
import { setLogin, setPayment } from '../../actions/signUp.actions';
import { message } from "antd";
import { NotificationContainer } from 'react-notifications';
import terms from '../../assets/terms/terms_condition.pdf';
import {
    Logo
} from "../../assets/images";
import { useHistory } from 'react-router-dom';
import LoadingOverlay from "react-loading-overlay";
import { setSuccessCode } from '../../actions/signUp.actions';
import { payments } from '../../actions/payment.actions';
import { getTokenDetails } from '../../axios/payment.axios';
const UpdatePlanPayment = (props) => {
    const tempUser = JSON.parse(localStorage.getItem("userDetails"));
    const product = useSelector(state => state.product.details)
    const dispatch = useDispatch();
    const history = useHistory();
    const [checkBox, setCheckbox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [coupon, setCoupon] = useState(undefined)

    const [input, setInput] = useState({
        cardNumber: "",
        cardName: "",
        cardExpMonth: "",
        cardExpYear: "",
        cardCVV: "",
        amount: product.price

    })
    const [validationReport, validate] = useFormValidation()

    const handleCheckboxChange = () => {
        setCheckbox(!checkBox);
    };
    const change = async (wizard) => {
        if (checkBox) {
            console.log("userDetails", tempUser)
            let temp = {
                userDetails: JSON.parse(localStorage.getItem("userDetails")),
                ...input,
                price: product.price_id,
                coupon: product.coupon,
                priceObject: product.priceObject,
                source: 'tvi'
            }
            console.log(temp, "temp")
            if (product.coupon)
                temp.coupon = product.coupon
            console.log(temp, "temp")
            setLoading(true)
            await updatePlanPayment(temp)
                .then(res => {
                    console.log(res.data, "result")
                    if (res.data.status) {
                        message.success("Payment successfull.")
                        setLoading(false)
                        dispatch(setSuccessCode(true))
                        history.push("/my-account")
                    }
                    else {
                        message.error("Payment was not successfull. Error: " + res.data.message)
                        // message.error("Payment was not successfull. Error: "+ res.data.message)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    message.error("Payment was not successfull. Error: " + err.message)
                    setLoading(false)
                })
        } else {
            message.error("Please check this box if you want to proceed")
        }

    }
    const handleChange = (event) => {
        let rules = JSON.parse(event.target.dataset.rules)
        validate(event.target.value, rules, event.target.name)
        setInput({ ...input, [event.target.name]: event.target.value })
    }
    const handleCoupon = async (coupon) => {
        try {
            setLoading(true)

            if (!coupon) {
                setLoading(false)
                message.error("No Coupon given");
                return 0;
            }
            let details = await getTokenDetails(coupon, product.price_id)
                .catch(err => {
                    setLoading(false)
                    message.error(err);
                })
            let newPrice

            if (details) {
                if (details.amount_off) {
                    newPrice = product.initialPrice - details.amount_off
                }
                else
                    newPrice = parseInt(product.initialPrice - (product.initialPrice * details.percent_off / 100))
                dispatch(payments.setPrice(newPrice))
                setLoading(false)
                message.success("Coupon applied");
                setCoupon(details.id)
                dispatch(payments.setCoupon(details.id))
            }
            else {
                dispatch(payments.setPrice(product.initialPrice))
                dispatch(payments.setCoupon(undefined))
            }
        } catch (error) {
            setLoading(false)
            message.error(error);
            dispatch(payments.setCoupon(undefined))
        }

    }

    return (
        <LoadingOverlay active={loading} spinner text="Loading ...">
            <div className='sign_up_partts_update_plan'>
                <div className='card_page_logo_image' >
                    <img src={Logo} />
                </div>
                <h3>Payment</h3>
                <p>Please proceed with your payment details...</p>
                <Form>
                    <div className='payment_boxxx'>
                        <NotificationContainer />
                        <p><b>Your Payment is secure</b></p>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Card Number*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Card Number"
                                className='sign_email'
                                name="cardNumber"
                                value={input.cardNumber}
                                data-rules={JSON.stringify({
                                    required: true,
                                    creditcard: true
                                })}
                                onChange={(event) => { handleChange(event) }}

                            />
                            {
                                validationReport.cardNumber && !validationReport.cardNumber.isValid ? <small>{validationReport.cardNumber.errorMessage} </small> : ""
                            }
                        </Form.Group>
                        <Row>
                            <Col lg="6" md="12">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Expiration Date</Form.Label>
                                    <Row>
                                        <Col lg="4" md="12">
                                            <Form.Control
                                                type="text"
                                                format="MM"
                                                placeholder="MM"
                                                className='sign_email'
                                                minLength={2}
                                                name="cardExpMonth"
                                                value={input.cardExpMonth}
                                                data-rules={JSON.stringify({
                                                    required: true,
                                                    month: true,
                                                    minlength: 2
                                                })}
                                                onChange={(event) => { handleChange(event) }}

                                            />
                                            {
                                                validationReport.cardExpMonth && !validationReport.cardExpMonth.isValid ? <small>{validationReport.cardExpMonth.errorMessage} </small> : ""
                                            }
                                        </Col>
                                        <Col lg="8" md="12">
                                            <Form.Control
                                                type="text"
                                                placeholder="YY"
                                                format="YYYY"
                                                className='sign_email'
                                                minLength={4}
                                                maxLength={4}
                                                name="cardExpYear"
                                                value={input.cardExpYear}
                                                data-rules={JSON.stringify({
                                                    required: true,
                                                    creditcard: true,
                                                    minlength: 4,
                                                    maxlength: 4,
                                                    year: true
                                                })}
                                                onChange={(event) => { handleChange(event) }}

                                            />
                                            {
                                                validationReport.cardExpYear && !validationReport.cardExpYear.isValid ? <small>{validationReport.cardExpYear.errorMessage} </small> : ""
                                            }
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col lg="6" md="12">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Security Code (CVV )*</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="XXX"
                                        className='sign_email'
                                        name="cardCVV"
                                        value={input.cardCVV}
                                        data-rules={JSON.stringify({
                                            required: true,
                                            cvv: true
                                        })}
                                        onChange={(event) => { handleChange(event) }}

                                    />
                                    {
                                        validationReport.cardCVV && !validationReport.cardCVV.isValid ? <small>{validationReport.cardCVV.errorMessage} </small> : ""
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Card Holder Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Name"
                                className='sign_email'
                                name="cardName"
                                value={input.cardName}
                                data-rules={JSON.stringify({
                                    required: true
                                })}
                                onChange={(event) => { handleChange(event) }}
                            />
                            {
                                validationReport.cardName && !validationReport.cardName.isValid ? <small>{validationReport.cardName.errorMessage} </small> : ""
                            }
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control type="text" placeholder="Enter a coupon code" onChange={(event) => { setCoupon(event.target.value) }} />
                            <button type="button" disable className='apply_but btn-primary mt-2' onClick={() => { handleCoupon(coupon) }}>Apply</button>
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Save card details for next time" />
            </Form.Group> */}

                    </div>
                    <Form.Group className="my-4 ml-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox"
                            checked={checkBox}
                            onChange={handleCheckboxChange}
                            id='terms' />
                        <label for='terms'>I have read and accept the <a href={terms} target='_blank'>Membership Policy</a>.</label>
                    </Form.Group>

                    <div className='sign_submit justify-content-start w-100'>
                        <Button variant="primary" type="button" className='w-100' onClick={() => { change("success") }}>
                            Buy Now
                        </Button>
                    </div>
                </Form>

            </div>
        </LoadingOverlay>
    );
};

export default UpdatePlanPayment;
import React, { useEffect } from "react";
import ReferalHeader from "../reffer/ReferalHeader";
import ReferalFooter from "../reffer/ReferalFooter";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./affiliate.css";
import {
  affiliate_banner,
  commission_circle,
  memberships_chart_list,
  tripvalet_affiliate_logo,
} from "../../assets/images";
import { useHistory } from "react-router-dom";
import { Buffer } from "buffer";

export default function Affiliate() {
  const history = useHistory();
  const gotoOrderCheckoutForm = () => {
    if (localStorage.getItem('referCodeStore')) {
      history.push(
        "/order-check-out?encode=" +
        Buffer.from(localStorage.getItem('referCodeStore'), "utf-8").toString("base64")
      );
    } else {
      history.push(
        "/order-check-out"
      );
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="landing">
      <ReferalHeader
        gotoOrderCheckoutForm={gotoOrderCheckoutForm}
      />

      <section
        className="baa_banner position-relative"
        style={{ background: "url(" + affiliate_banner + ")" }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col
              md={5}
              sm={6}
              className="baab_content position-relative text-center text-white"
            >
              <img
                src={tripvalet_affiliate_logo}
                className="img-fluid"
                alt="Brand"
              />
              <h2>BECOMING AN AFFILIATE</h2>
              <a to="#" onClick={gotoOrderCheckoutForm} className="btn_style_one rounded-pill btn">
                <span>SIGN UP NOW</span>
              </a>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="baa_cnt_area">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="baa_cnt text-center">
                <h2>
                  TRIPVALET INCENTIVE MEMBERS AUTOMATICALLY RECEIVE AN
                  INVITATION TO CREATE A SHAREABLE AFFILIATE LINK UPON
                  ENROLLMENT.WE OFFER A TWO TIER AFFILIATE PROGRAM WITH A 50%
                  COMMISSION PAYOUT ON ALL REFERRALS.
                </h2>
                <div className="sep">
                  <span className="sep_dark" />
                </div>
                <img
                  src={commission_circle}
                  alt="Commission"
                  className="img-fluid"
                />
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "1.063rem",
                    fontWeight: "500",
                    margin: "1rem 0 .5rem",
                  }}
                >
                  * ​This is an example of potential earnings
                </div>
                <img
                  alt="memberships_chart_list"
                  src={memberships_chart_list}
                  className="img-fluid w-100"
                />
              </div>
              <div className="lifestyle_bonus">
                <div className="btn_section text-center mt-5">
                  <a to="#" onClick={gotoOrderCheckoutForm} class="btn_style_one rounded-pill btn">
                    <span>SIGN UP NOW</span>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section_blank" />

      <ReferalFooter
        gotoOrderCheckoutForm={gotoOrderCheckoutForm}
      />
    </div>
  );
}

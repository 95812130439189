import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReferalFooter from "../reffer/ReferalFooter";
import ReferalHeader from "../reffer/ReferalHeader";
import "./faq.css";
import { tripvalet } from "../../assets/images";
import { useHistory } from "react-router-dom";
import { Buffer } from "buffer";

export default function FAQ() {
  const history = useHistory();
  const gotoOrderCheckoutForm = () => {
    if (localStorage.getItem('referCodeStore')) {
      history.push(
        "/order-check-out?encode=" +
        Buffer.from(localStorage.getItem('referCodeStore'), "utf-8").toString("base64")
      );
    } else {
      history.push(
        "/order-check-out"
      );
    }
  };
  useEffect(() => {
    console.log('FAQ');
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="landing">
      <ReferalHeader
        gotoOrderCheckoutForm={gotoOrderCheckoutForm}
      />
      <section className="faq_cnt_area">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="faq_cnt">
                <div className="faq_title text-center">
                  <h2>FAQ</h2>
                  <div className="sep" style={{ marginTop: ".6rem" }}>
                    <span className="sep_dark"></span>
                  </div>
                </div>
                <div className="faq_item">
                  <h2>How do I cancel my 7 Day Trial before I get charged?</h2>
                  <p>
                    7 DAY CANCELLATION POLICY: ALL CANCELLATIONS MUST BE
                    RECEIVED ELECTRONICALLY ON OR BEFORE THE SIXTH DAY WHILE ON
                    THE SEVEN DAY TRIAL. THE CREDIT CARD USED TO ENROLL WILL BE
                    CHARGED ON DAY SEVEN. TO CANCEL A MEMBERSHIP, SEND AN EMAIL
                    WITH MEMBERS NAME AND REASON FOR CANCELLATION TO
                    SUPPORT@TRIPVALET.COM "DUE TO THE NATURE OF OUR MEMBERSHIP
                    NO REFUNDS ON THE MEMBERSHIP ARE AVAILABLE AFTER THE TRIAL
                    PERIOD."
                  </p>
                </div>
                <div className="faq_item">
                  <h2>
                    Do my customers have to take a timeshare presentation in
                    order to take advantage of your vacation certificates?
                  </h2>
                  <p>
                    No, your customers will never be required to attend any
                    sales or timeshare presentation. These are real, top quality
                    vacations with no hidden fees or strings attached. Remember,
                    we are simply filling otherwise empty rooms which the hotels
                    and resorts turn into additional revenue from other areas of
                    their property, such as room service, spa, in-house
                    restaurant, bar purchases, extra day stay, etc.
                  </p>
                </div>
                <div className="faq_item">
                  <h2>How are you able to offer these incentives?</h2>
                  <p>
                    We contract with the world-leading hotel chains, cruise
                    lines, and resorts to help them fill their otherwise empty
                    rooms. We help fill empty rooms which the hotels, resorts,
                    and cruise lines turn into additional revenue from other
                    areas of their property and future bookings. Hotels rarely
                    experience full occupancy, yet they remain open, providing
                    rooms and services at fixed costs. So when hotels give out
                    their rooms by partnering with marketing companies like us,
                    they might miss out on the room fee, but they already didn't
                    have that in the first place, at least now they have the
                    opportunity to gain a customer for their in-house
                    restaurants, room service, their casinos, an extra day stay,
                    future bookings, etc. The magic of why our program works so
                    well is the fact that it's a win-win for everyone involved.
                    You win because you gain more business from using our
                    incentives. Your customers win because they received an
                    awesome incentive gift from you. The hotels, resorts, and
                    cruise lines win because they gain additional revenue from
                    other areas of their establishment that they otherwise would
                    not have been able to get and they also have a new client
                    that may book with them in the future.
                  </p>
                </div>
                <div className="faq_item">
                  <h2>Do the certificates have an expiration date?</h2>
                  <p>
                    Yes, all certificates created will be assigned an expiration
                    date 30 days out. Please note that the expiration date
                    printed on the certificates only pertains to the actual
                    activation/redemption of the certificate. After your
                    customers activate a vacation certificate, they will have at
                    least 12 months to travel.
                  </p>
                </div>
                <div className="faq_item">
                  <h2>
                    Do my customers have to set their reservations and travel by
                    the expiration date printed on the certificate?
                  </h2>
                  <p>
                    No. The expiration date pertains to redemption/activation of
                    the vacation certificate only. Once your customer
                    redeems/activates a vacation certificate they will then have
                    at least 12 months to travel.
                  </p>
                </div>
                <div className="faq_item">
                  <h2>Do my customers get to choose the destination?</h2>
                  <p>
                    Yes, your customers do get to choose the destinations that
                    they wish to travel to.
                  </p>
                </div>
                <div className="text-center">
                  <img alt="tripvalet" src={tripvalet} className="img-fluid" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ReferalFooter
        gotoOrderCheckoutForm={gotoOrderCheckoutForm}
      />
    </div>
  );
}

import React, { useState, useEffect } from "react";
//import "./styles.css";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import LeftpanelMember from "../../../components/leftpanel/leftpanel-member";
import HeaderPart from "../../../components/layout/header/header";
import LoadingOverlay from "react-loading-overlay";
import "./training-video-dashboard.css";


import {
    GettingStarted,
    Insurance,
    RealAgents,
    TViAcademy,
} from "../../../assets/images";

const TravelDashboardPart = () => {
    return (
        <div>
            <HeaderPart />
            <div className="main_cont">
                <LeftpanelMember />
                <div className="right_cont">
                    <div className="right_banner_banner_cnt">
                        <Row>
                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8KgwZ1GfBMycEyRiBwLPbi0" target="_blank" className="banner1_box travel_boxxx">
                                        <img src={GettingStarted} className="img-fluid" />
                                        <div className="banner1_overlay">Getting Started</div>
                                    </a>
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="/coming-soon" className="banner1_box travel_boxxx">
                                        <img src={Insurance} className="img-fluid" />
                                        <div className="banner1_overlay">Training for Insurance</div>
                                    </a>
                                </div>
                            </Col>

                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8LNp-9tHNX7cJxqqVblfghA" target="_blank" className="banner1_box travel_boxxx">
                                        <img src={RealAgents} className="img-fluid" />
                                        <div className="banner1_overlay">Training for Real Estate Agents</div>
                                    </a>
                                </div>
                            </Col>

                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="/tvi-academy" className="banner1_box travel_boxxx">
                                        <img src={TViAcademy} className="img-fluid" />
                                        <div className="banner1_overlay">TVI Academy</div>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TravelDashboardPart;
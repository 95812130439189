import React, { useState, useEffect } from "react";
import "./affiliate-dashboard.css";
import DataTable from "react-data-table-component";
import { Button } from "react-bootstrap";
import axios from "../../axios/axios";
import LoadingOverlay from "react-loading-overlay";

const columns = [
    {
        name: "First Name",
        selector: (row) => row.lead_first_name,
        sortable: true,

    },
    {
        name: "Email",
        selector: (row) => row.lead_email,
        sortable: true,

    },
    {
        name: "Date",
        selector: (row) => new Date(row.createdAt).toLocaleDateString(),
        sortable: true,

    }
];

const customStyles = {
    header: {
        style: {
            minHeight: "56px",
        },
    },
    headRow: {
        style: {
            borderTopStyle: "solid",
            borderTopWidth: "1px",
        },
    },
    headCells: {
        style: {
            "&:not(:last-of-type)": {
                borderRightStyle: "solid",
                borderRightWidth: "1px",
            },
            fontSize: "17px",
            color: "#004aad",
        },
    },

    cells: {
        style: {
            "&:not(:last-of-type)": {
                borderRightStyle: "solid",
                borderRightWidth: "1px",
            },
            fontSize: "16px",
        },
    },
};

const AffliateLeadTable = (props) => {
    const [tabledata, setTabledata] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        limit: 5,
        page: 1,
        totalPage: 7,
        totalRows: 10,
    });
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));

    useEffect(() => {
        setLoading(true);
        axios
            .get(`/affiliate/get-lead/${userDetails._id}/tvi?limit=${limit}&page=${page}`)
            .then((res) => {
                console.log(res.data.status);
                if (res.data.status) {
                    setTabledata(res.data.data);
                    setPagination(res.data.pagination);
                } else {
                    setTabledata([]);
                }
                setLoading(false)
            })
            .catch((err) => {
                console.error(err);
                setLoading(false)
            });

    }, [page, limit])

    const handlePerRowsChange = (newPerPage) => {
        setLimit(newPerPage);
        setPage(1);
    };
    const changePage = (newPage) => {
        setPage(newPage);
    };
    return (
        <LoadingOverlay active={loading} spinner text="Loading ...">
            <div className="chart_partts">
                <h2>My Leads</h2>
                <DataTable
                    columns={columns}
                    data={tabledata}
                    pagination
                    striped
                    responsive
                    customStyles={customStyles}
                    subHeaderAlign="right"
                    subHeaderWrap
                    paginationDefaultPage={page}
                    paginationServer
                    paginationTotalRows={pagination.totalRows}
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 40, 100]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={changePage}

                />
            </div>
        </LoadingOverlay>
    );
};
export default AffliateLeadTable;
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./lead-generate-css.css";
import { marketing_image_lead } from "../../assets/images";
import axios from "../../axios/axios";
import LoadingOverlay from "react-loading-overlay";
import { Buffer } from "buffer";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";

const LeadGenerateForm = () => {
    const params = useParams();
    const base64Value = params.code;

    const [loading, setLoading] = useState(false);
    const [check, setCheck] = useState(false);
    const [formData, setFormData] = useState({
        userId: "",
        lead_first_name: "",
        lead_email: "",
        source: "tvi",
    });

    useEffect(() => {
        const buffer = Buffer.from(base64Value, "base64");
        const originalValue = buffer.toString("utf-8");
        formData.userId = originalValue;
        setFormData({ ...formData });
    }, [base64Value]);



    const handleOnChange = (e) => {
        const { name, value } = e.target;

        formData[name] = value;

        setFormData({ ...formData });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        axios
            .post("/affiliate/lead/save", formData)
            .then((res) => {
                setLoading(false);

                if (res.data.status) {
                    NotificationManager.success(res.data.message);
                    setFormData({
                        userId: formData.userId,
                        lead_first_name: "",
                        lead_email: "",
                        source: "tvi",
                    });
                    setCheck(false);
                } else {
                    NotificationManager.error(res.data.message);
                }
            })
            .catch((err) => {
                console.error(err);
                NotificationManager.error(err);
                setLoading(false);
            });
    };

    return (
        <>
            <LoadingOverlay active={loading} spinner text="Loading ...">
                <NotificationContainer />
                <div className="im_card">
                    <img src={marketing_image_lead} className="img-fluid" />
                    <div className="im_form mt-4">
                        <Form onSubmit={handleFormSubmit} id="formSubmit">
                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>
                                            First Name <span className="req_mark">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="First Name"
                                            required
                                            name="lead_first_name"
                                            onChange={handleOnChange}
                                            value={formData.lead_first_name}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>
                                            Email <span className="req_mark">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            required
                                            name="lead_email"
                                            value={formData.lead_email}
                                            onChange={handleOnChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <div className="d-grid">
                                        <Button
                                            type="submit"
                                            variant="dark"
                                            className="imf_button rounded-pill"
                                        >
                                            Send
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    required
                                    onClick={() => {
                                        setCheck(true);
                                    }}
                                    checked={check}
                                    label="I agree to terms & conditions provided by the company. By providing my email, I agree to receive communication from the business and understand I can unsubscribe at any time"
                                />
                            </Form.Group>
                        </Form>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    );
};

export default LeadGenerateForm;

const Facebook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fill="currentcolor"
      width="22"
      height="22"
      viewBox="0 0 512 512"
    >
      <path d="M283.122 122.174v46.583h83.424l-9.045 74.367h-74.379v268.375h-98.726V243.124h-51.443v-74.367h51.443v-56.302c0-27.82-2.096-41.02 9.725-62.578C205.948 28.32 239.308-.174 297.007.512c57.713.711 82.04 6.263 82.04 6.263l-12.501 79.257s-36.853-9.731-54.942-6.263c-18.065 3.469-28.482 14.597-28.482 42.405z" />
    </svg>
  );
};

const Instagram = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentcolor"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        d="M5.87.123C4.242.196 2.83.594 1.69 1.729.548 2.869.155 4.286.081 5.897.037 6.902-.231 14.498.545 16.49a5.04 5.04 0 002.91 2.903c.634.246 1.356.412 2.416.461 8.86.401 12.145.183 13.53-3.364.246-.631.415-1.353.462-2.41.405-8.883-.066-10.809-1.61-12.351C17.027.507 15.586-.325 5.87.123m.081 17.944c-.97-.043-1.496-.205-1.848-.341a3.255 3.255 0 01-1.888-1.883c-.591-1.514-.395-8.703-.342-9.866.051-1.14.282-2.18 1.086-2.985C3.954 2 5.24 1.513 13.993 1.908c1.142.052 2.186.282 2.992 1.084.995.993 1.489 2.288 1.087 11.008-.044.968-.206 1.493-.342 1.843-.901 2.308-2.973 2.628-11.779 2.224M14.09 4.69c0 .657.534 1.19 1.194 1.19.66 0 1.195-.533 1.195-1.19a1.194 1.194 0 00-2.39 0M4.864 9.988a5.103 5.103 0 005.11 5.097 5.103 5.103 0 005.109-5.097 5.102 5.102 0 00-5.11-5.096 5.102 5.102 0 00-5.11 5.096m1.794 0A3.313 3.313 0 019.972 6.68a3.313 3.313 0 013.317 3.308 3.313 3.313 0 01-3.317 3.31 3.313 3.313 0 01-3.316-3.31"
      ></path>
    </svg>
  );
};
const YouTube = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      width="24"
      height="24"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.496 7.132A1 1 0 0 0 8 8v8a1 1 0 0 0 1.496.868l7-4a1 1 0 0 0 0-1.736l-7-4ZM13.984 12 10 14.277V9.723L13.984 12Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 12c0-3.75 0-5.625.955-6.939A5 5 0 0 1 2.06 3.955C3.375 3 5.251 3 9 3h6c3.75 0 5.625 0 6.939.955a5 5 0 0 1 1.106 1.106C24 6.375 24 8.251 24 12c0 3.75 0 5.625-.955 6.939a5 5 0 0 1-1.106 1.106C20.625 21 18.749 21 15 21H9c-3.75 0-5.625 0-6.939-.955A5 5 0 0 1 .955 18.94C0 17.625 0 15.749 0 12Zm9-7h6c1.92 0 3.198.003 4.167.108.932.1 1.337.276 1.596.465.255.185.479.409.664.664.189.26.364.664.465 1.596.105.969.108 2.248.108 4.167 0 1.92-.003 3.198-.108 4.167-.1.932-.276 1.337-.465 1.596-.185.255-.409.479-.664.664-.259.189-.664.364-1.596.465-.969.105-2.248.108-4.167.108H9c-1.92 0-3.198-.003-4.167-.108-.932-.1-1.337-.276-1.596-.465a3.003 3.003 0 0 1-.664-.664c-.189-.259-.364-.664-.465-1.596C2.003 15.198 2 13.92 2 12c0-1.92.003-3.198.108-4.167.1-.932.276-1.337.465-1.596a3 3 0 0 1 .664-.664c.259-.189.664-.364 1.596-.465C5.802 5.003 7.08 5 9 5Z"
      />
    </svg>
  );
};

const Heart = () => {
  return (
    <svg fill="currentColor" width="24" height="24">
      <path
        d="M8.182 5.004c-.254 0-.512.024-.771.072-1.678.315-3.054 1.772-3.346 3.543a4.663 4.663 0 0 0 .203 2.33c.904 2.909 5.939 6.453 7.46 7.47a.482.482 0 0 0 .547 0c1.52-1.017 6.552-4.56 7.458-7.47a4.696 4.696 0 0 0 .203-2.33c-.293-1.771-1.67-3.228-3.346-3.543-1.63-.301-3.225.37-4.177 1.76-.187.274-.64.274-.825 0-.801-1.17-2.06-1.832-3.406-1.832m3.82 14.499c-.288 0-.577-.084-.83-.252-2.035-1.361-6.875-4.864-7.856-7.995a5.665 5.665 0 0 1-.238-2.8C3.444 6.244 5.11 4.49 7.227 4.095A5.054 5.054 0 0 1 12 5.74a5.068 5.068 0 0 1 4.774-1.647c2.116.396 3.783 2.149 4.148 4.363a5.67 5.67 0 0 1-.24 2.809c-.97 3.116-5.814 6.623-7.851 7.985a1.494 1.494 0 0 1-.83.252"
        fill-rule="evenodd"
      />
    </svg>
  );
};
const Share = () => {
  return (
    <svg fill="currentColor" width="24" height="24">
      <path
        d="M14.5 14a.5.5 0 0 1 .5.5v2.793L20.293 12 15 6.707V9.5a.499.499 0 0 1-.5.5c-.006-.002-.009 0-.014 0-.819 0-7.079.222-9.88 6.837 2.67-2.086 5.89-3.042 9.582-2.848.136.007.238.012.288.012L14.5 14m-11 5a.499.499 0 0 1-.481-.635C5.304 10.189 11.981 9.145 14 9.017V5.5a.5.5 0 0 1 .854-.354l6.5 6.5a.502.502 0 0 1 0 .708l-6.5 6.5A.5.5 0 0 1 14 18.5v-3.519c-4.043-.19-7.455 1.121-10.143 3.869A.5.5 0 0 1 3.5 19"
        fill-rule="evenodd"
      />
    </svg>
  );
};

export { Facebook, Instagram, Heart, Share, YouTube };

import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  ProSidebarProvider,
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
// import "react-pro-sidebar/dist/css/styles.css";
import "./leftpanel-member.css";
import {
  Home_Img,
  Plane_Img,
  Shopping_Img,
  User_Img,
  Dollar_Img,
  Calendar_Img,
  Palm_Img,
  Relation_Img,
  CErtificates,
  PlayIcon,
  Resources,
  Partners,
} from "../../assets/images";
import axios from "../../axios/axios";
import { message } from "antd";
import apiConfig from "../../config/api.config";
import countryConfig from "../../config/country.config";

const LeftpanelMember = (props) => {
  const [user, setUser] = useState(
    localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))
      : null
  );
  const [arriviaUserId, setArriviaUserId] = useState(
    user ? user.arriviaUserId : null
  );

  const [show, setShow] = useState(false);
  const [first, setFirst] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [last, setLast] = useState("");
  const [address, setAddress] = useState("");
  const [loadTravel, setLoadTravel] = useState("");
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const modalClose = () => {
    setLoadTravel(false);
    setShow(false);
  };

  const [menus, setMenus] = useState([]);

  const handleFirst = (event) => {
    switch (event.target.name) {
      case "first":
        setFirst(event.target.value);
        break;
      case "last":
        setLast(event.target.value);
        break;

      case "address":
        setAddress(event.target.value);
        break;
      case "city":
        setCity(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;

      default:
        setCountry(event.target.value);
        break;
    }
  };
  const modalShow = () => setShow(true);
  const getBaseURL = () => {
    switch (process.env.ENV) {
      case "STAGING":
        return process.env.REACT_APP_API_URL_STAGING;

      case "DEVELOPEMENT":
        return process.env.REACT_APP_API_URL_LOCAL;

      default:
        return process.env.REACT_APP_API_URL_STAGING;
    }
  };
  const arriviaRegister = async () => {
    let isRegistered = false;
    if (!first || !phone || !city || !country || !last || !address) {
      console.log(first);
      message.warning("Please fill up the form properly");
    } else {
      modalClose();
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
        },
      };

      let data = {
        firstName: first,
        lastName: last,
        phone,
        country,
        city,
        address,
      };
      await axios
        .post(`${apiConfig.api}user/arrivia_register`, data, config)
        .then((res) => {
          console.log("aarivia res", res);
          if (res.data.status) {
            message.warning("Registering to Dashboard, please wait");
            setArriviaUserId(res.data.data);
            let userDetails = JSON.parse(localStorage.getItem("userDetails"));
            console.log(userDetails, "us---");
            userDetails.arriviaUserId = res.data.data;
            console.log(userDetails, "us");
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
            isRegistered = true;
            window.open(apiConfig.arriviaRedirectUrl + res.data.data, "_blank");
          } else {
            message.error("Something went wrong. Login failed!");
          }
        })
        .catch((err) => {
          console.log("err", err);
          message.error(
            err.response.data.message ??
            "Something went wrong. Please try again letter"
          );
        });

      if (isRegistered) {
        message.success("User is successfully registered");
      } else {
      }
    }
  };
  const arriviaLogin = async () => {
    setLoadTravel(true);
    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("AuthToken")}` },
    };
    if (!arriviaUserId) {
      // window.open("/", "_self");
      modalShow();
      return 0;
    }
    // else message.success("Red, please wait");
    await axios
      .post(`${apiConfig.api}user/arrivia_login`, {}, config)
      .then((res) => {
        if (res.data.status) {
          setLoadTravel(false);
          message.success("Redirecting to dashboard. Please wait...");
          window.open(apiConfig.arriviaRedirectUrl + res.data.data);
          // window.open(apiConfig.arriviaRedirectUrl + res.data.data, "_blank");
        } else {
          message.error("Something went wrong. Login failed!");
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoadTravel(false);
        message.error(
          err.response.data.message ??
          "Something went wrong. Please try again letter"
        );
      });
  };

  const getLink = async () => {
    setLoading(true);

    try {
      await axios
        .get(`/get-links`)
        .then((res) => {
          console.log("links", res.data.data);
          if (res.data.status === true) {
            setLinks(res.data.data);
            // setPagination(res.data.pagination);
            // console.log("pagination", res.data.pagination);
            setLoading(false);
            // message.success("link fetched");
          } else {
            message.error("failed fetching member");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getAllMenu = () => {
    axios
      .get("/get-all-menu")
      .then((res) => {
        let { tvi_menu } = res.data;

        setMenus(tvi_menu);

        // this.setState({  tvi_menu });
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong");
      });
  };

  useEffect(() => {
    // getLink();
    getAllMenu();
  }, []);

  return (
    <>
      <ProSidebarProvider>
        <Sidebar>
          <Menu>
            <div className="left_panel_partts">
              <Nav as="ul" className="ml-auto mr-auto nav_dash">
                {menus.map((menu, key) => {
                  let sub_menu = menu.sub_menu.map((res) => {
                    return JSON.parse(res);
                  });

                  return (
                    <>
                      {console.log(localStorage.getItem("GPTtoken"))}
                      {menu.sub_menu.length === 0 ? (
                        <Nav.Item as="li" key={key}>
                          {menu.url.includes("http") ? (
                            <span>
                              {menu.menu == "Go To GPT Good News" ? (

                                <>
                                  <a href={menu.url + '' + localStorage.getItem("GPTtoken")} target="_blank" className={localStorage.getItem("GPTtoken") == '' ? 'd-none' : ''}>
                                    <span className="dasg__imgg">
                                      <img src={menu.icon} alt={menu.menu} />
                                    </span>
                                    <span>{menu.menu}</span>
                                  </a>
                                </>
                              ) : (<>

                                <a href={menu.url} target="_blank">
                                  <span className="dasg__imgg">
                                    <img src={menu.icon} alt={menu.menu} />
                                  </span>
                                  <span>{menu.menu}</span>
                                </a>
                              </>)}
                            </span>

                          ) : (
                            <>
                              {menu.url == "/arriviaLogin" ? (
                                <>
                                  <NavLink to="#" onClick={arriviaLogin} exact>
                                    <span className="dasg__imgg">
                                      <img src={menu.icon} alt={menu.menu} />
                                    </span>
                                    <span>{menu.menu}</span>
                                  </NavLink>
                                </>
                              ) : (
                                <>
                                  <NavLink exact to={menu.url}>
                                    <span className="dasg__imgg">
                                      <img src={menu.icon} alt={menu.menu} />
                                    </span>
                                    <span>{menu.menu}</span>
                                  </NavLink>
                                </>
                              )}
                            </>
                          )}
                        </Nav.Item>
                      ) : (
                        <SubMenu
                          key={key}
                          label={menu.menu}
                          icon={<img src={menu.icon} alt={menu.menu} />}
                        >
                          {sub_menu.map((sub, key) => {
                            return (
                              <>
                                {sub.nested_sub_menu?.length === 0 ? (
                                  <MenuItem
                                    key={key}
                                    href={sub.sub_url}
                                    target={
                                      sub.sub_url.includes("http")
                                        ? "_blank"
                                        : ""
                                    }
                                  >
                                    {sub.sub_menu}
                                  </MenuItem>
                                ) : (
                                  <SubMenu label={sub.sub_menu}>
                                    {sub.nested_sub_menu?.map((nested, key) => {
                                      return (
                                        <MenuItem
                                          key={key}
                                          target={
                                            nested.nested_sub_menu_url.includes(
                                              "http"
                                            )
                                              ? "_blank"
                                              : ""
                                          }
                                          href={nested.nested_sub_menu_url}
                                        >
                                          {nested.nested_sub_menu_name}
                                        </MenuItem>
                                      );
                                    })}
                                  </SubMenu>
                                )}
                              </>
                            );
                          })}
                        </SubMenu>
                      )}
                    </>
                  );
                })}
              </Nav>
            </div>
          </Menu>
        </Sidebar>
      </ProSidebarProvider>
      <Modal show={show}>
        <Modal.Body>
          <div className="popuptext">
            <Button
              variant="secondary"
              className="close_button"
              onClick={modalClose}
            ></Button>
            <h3>Title</h3>
            <div className="modal_form_group">
              <Form>
                <Row>
                  <Col lg="6" md="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter first name"
                        name="first"
                        className="modal_t_field1"
                        value={first}
                        onChange={handleFirst}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter last name"
                        className="modal_t_field1"
                        name="last"
                        value={last}
                        onChange={handleFirst}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Phone Number"
                    className="modal_t_field1"
                    name="phone"
                    value={phone}
                    onChange={handleFirst}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    className="modal_t_area"
                    placeholder="Enter Address"
                    name="address"
                    value={address}
                    onChange={handleFirst}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter City"
                    className="modal_t_field1"
                    name="city"
                    value={city}
                    onChange={handleFirst}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    className="modal_t_field"
                    name="country"
                    value={country}
                    onChange={handleFirst}
                  >
                    {countryConfig.map((value, key) => {
                      return (
                        <option key={key} value={value.key}>
                          {value.label}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <div className="modal_submit">
                  <Button
                    variant="primary"
                    type="button"
                    className="sub_but"
                    onClick={() => {
                      arriviaRegister();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="modal_close">
            <h3>Are you sure you want to logout?</h3>
            <Button
              variant="secondary"
              onClick={handleClose}
              className="close_buuut"
            ></Button>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer_but">
          <Button variant="secondary" onClick={logout} className="yesbut">
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose} className="nobut">
            No
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default LeftpanelMember;

const dd = () => {
  const links = [];
  const arriviaLogin = () => { };
  const getBaseURL = () => { };
  return (
    <>
      {/* <Nav.Item as="li">
                  <NavLink exact to="/">
                    <span className="dasg__imgg">
                      <img src={Home_Img} alt="" />
                    </span>
                    <span>Home</span>
                  </NavLink>
                </Nav.Item> */}

      <Nav.Item as="li">
        <NavLink to="#" onClick={arriviaLogin} exact>
          <span className="dasg__imgg">
            <img src={Plane_Img} alt="" />
          </span>
          <span>Travel Deals</span>
        </NavLink>
      </Nav.Item>

      {/* {links &&
                  links.length > 0 &&
                  links.map((link) => {
                    return (
                      <Nav.Item as="li">
                        <NavLink exact to={link.link}>
                          <span className="dasg__imgg">
                            <img src={Shopping_Img} alt="" />
                          </span>
                          <span>{link.name}</span>
                        </NavLink>
                      </Nav.Item>
                    );
                  })} */}
      <Nav.Item as="li">
        <a
          exact
          to="#"
          href={getBaseURL() + "user/benifithub"}
          // to={links.length ? links[0].link : "/coming-soon"}
          target="_blank"
        >
          <span className="dasg__imgg">
            <img src={Shopping_Img} alt="" />
          </span>
          <span>Shopping & Entertainment</span>
        </a>
      </Nav.Item>
      {/* <SubMenu
                  label="Travel Certificates"
                  icon={<img src={CErtificates} alt="" />}
                >
                  <MenuItem href="/travel-certificates"> Email </MenuItem>
                  <MenuItem href="/print"> Print </MenuItem>
                  <MenuItem href="/coming-soon"> Embed Code </MenuItem>
                </SubMenu> */}
      {/* <Nav.Item as="li">
                                <NavLink
                                exact
                                to="/my-account"
                                >
                                <span className="dasg__imgg"><img src={PlayIcon} alt=""/></span>
                                <span>Training Videos</span>
                                </NavLink>
                            </Nav.Item> */}

      <SubMenu label="Training Videos" icon={<img src={PlayIcon} alt="" />}>
        <MenuItem
          target="_blank"
          // href={
          //   links.length
          //     ? links[5].link
          //     : "https://www.youtube.com/playlist?list=PLlU3YVtkpZ8Lmk-gbxME8Kg7bbJS-vIs1"
          // }
          href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8KgwZ1GfBMycEyRiBwLPbi0"
        >
          {" "}
          Getting Started{" "}
        </MenuItem>
        <MenuItem
          target="_blank"
          href={
            links.length
              ? links[6].link
              : "https://www.youtube.com/playlist?list=PLlU3YVtkpZ8LNovRLmE-kdH4dBkys-JvW "
          }
        >
          {" "}
          Training for Insurance{" "}
        </MenuItem>
        <MenuItem
          target="_blank"
          href={
            links.length
              ? links[7].link
              : "https://www.youtube.com/playlist?list=PLlU3YVtkpZ8LNp-9tHNX7cJxqqVblfghA "
          }
        >
          {" "}
          Training for Real Estate Agents{" "}
        </MenuItem>
        {/* <MenuItem
                    target="_blank"
                    href={
                      links.length
                        ? links[8].link
                        : "https://www.youtube.com/playlist?list=PLlU3YVtkpZ8J9_lYATrkXZwhfu2uZCWry"
                    }
                  >
                    {" "}
                    General Training{" "}
                  </MenuItem> */}
        <SubMenu
          label="TVI Academy"
        // icon={<img src={PlayIcon} alt="" />}
        >
          <MenuItem
            target="_blank"
            href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8LeOUcdLtlJGLiP39UZdeL8"
          >
            Public Speaking Essentials
          </MenuItem>

          <MenuItem
            target="_blank"
            href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8JQ5ZnJSoKs6cx4FanjGTe1"
          >
            Instagram Essentials
          </MenuItem>

          <MenuItem
            target="_blank"
            href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8JzUrdeANEGI8WO7_-vNvv7"
          >
            9 Principles of Negotiation
          </MenuItem>

          <MenuItem
            target="_blank"
            href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8LjAuo0bKf3h-lOfbJQ6EXS"
          >
            Psychology of Marketing
          </MenuItem>

          <MenuItem
            target="_blank"
            href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8KKuaVfLyT-T8K4YHl7G_w9"
          >
            Confidence Essentials
          </MenuItem>

          <MenuItem
            target="_blank"
            href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8KH4SqGrKBsjkLEc9JvbkqY"
          >
            Sales Essentials
          </MenuItem>

          <MenuItem
            target="_blank"
            href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8IjUe3zCmHGMr_F0Exld0Kv"
          >
            LinkedIn Basics For Business
          </MenuItem>

          <MenuItem
            target="_blank"
            href="https://www.youtube.com/playlist?list=PLlU3YVtkpZ8JQk_0nW2NfggmanSRSmyYU"
          >
            Facebook Basics
          </MenuItem>

          <MenuItem
            target="_blank"
            href="https://youtube.com/playlist?list=PLlU3YVtkpZ8LNovRLmE-kdH4dBkys-JvW&si=EnSIkaIECMiOmarE"
          >
            Training for Insurance
          </MenuItem>

          <MenuItem
            target="_blank"
            href="https://youtube.com/playlist?list=PLlU3YVtkpZ8LNp-9tHNX7cJxqqVblfghA&si=EnSIkaIECMiOmarE"
          >
            Training for Realtors
          </MenuItem>
        </SubMenu>
      </SubMenu>

      {/* <SubMenu
                  label="Resources"
                  icon={<img src={Resources} alt="" />}
                >
                  <MenuItem href="/wix-template">
                    {" "}
                    Wix Website Templates{" "}
                  </MenuItem>
                  <MenuItem href="/shareable-images">
                    {" "}
                    Shareable Images{" "}
                  </MenuItem>
                  <MenuItem
                    target="_blank"
                    href="https://drive.google.com/file/d/1S478XxCKbFzWukkK3qp66A9S494zMCBB/view?usp=sharing"
                  >
                    {" "}
                    Certificate Guide{" "}
                  </MenuItem>
                  <MenuItem
                    target="_blank"
                    href="https://drive.google.com/drive/folders/1HYfosTCgPV1dpP_Pvbj79gcV12qP2A1o"
                  >
                    {" "}
                    Contents to Share{" "}
                  </MenuItem>
                  <MenuItem
                    target="_blank"
                    href="https://docs.google.com/document/d/1jZRRCHgXkZZUAJByH01zMHTM68SjtF5Qro7fp8HVkmw/edit?usp=sharing"
                  >
                    {" "}
                    FAQs{" "}
                  </MenuItem>
                </SubMenu> */}
      <SubMenu label="Preferred Partners" icon={<img src={Partners} alt="" />}>
        <MenuItem href="/coming-soon"> Coming Soon </MenuItem>
        {/* <MenuItem href="/coming-soon"> Social Media </MenuItem>
                  <MenuItem href="/coming-soon"> PR </MenuItem>
                  <MenuItem href="/coming-soon"> Business Financing </MenuItem>
                  <MenuItem href="/coming-soon"> Working Live </MenuItem>
                  <MenuItem href="/coming-soon"> JKN Programs </MenuItem> */}
      </SubMenu>
      {/* {user && user.affiliate == "" ? */}
      <Nav.Item as="li">
        <NavLink
          exact
          to="affiliate-dashboard"
        // onClick={() => {
        //   window.open(
        //     "https://affiliate.tripvaletincentives.com/login"
        //   );
        // }}
        >
          <span className="dasg__imgg">
            <img src={Relation_Img} alt="" />
          </span>
          <span>Affiliate Dashboard </span>
        </NavLink>
      </Nav.Item>
      <Nav.Item as="li">
        <NavLink
          exact
          // onClick={() => {
          //   window.open(
          //     "https://www.tripvaletincentives.com/account/my-account"
          //   );
          // }}

          to="/my-account"
        // target="_blank"
        >
          <span className="dasg__imgg">
            <img src={User_Img} alt="" />
          </span>
          <span>My Account</span>
        </NavLink>
      </Nav.Item>

      {/* <Nav.Item as="li">
                      <a
                        href={
                          links.length
                            ? links[2].link
                            : "https://www.tripvaletincentives.com/affiliate"
                        }
                        target="_blank"
                        // onClick={() => {
                        //   window.open(
                        //     "https://www.tripvaletincentives.com/affiliate"
                        //   );
                        // }}
                      >
                        <span className="dasg__imgg">
                          <img src={Dollar_Img} alt="" />
                        </span>
                        <span>Get paid to refer a friend</span>
                      </a>
                    </Nav.Item> */}

      <Nav.Item as="li">
        <NavLink exact to="/events">
          <span className="dasg__imgg">
            <img src={Calendar_Img} alt="" />
          </span>
          <span>Calendar of Events</span>
        </NavLink>
      </Nav.Item>
      <Nav.Item as="li">
        <a
          href={
            links.length
              ? links[3].link
              : "https://www.tripvalet.com/escape-trips"
          }
          target="_blank"
        // onClick={() =>
        //   links.length
        //     ? links[3].link
        //     : "https://www.tripvalet.com/escape-trips";
        // }
        >
          <span className="dasg__imgg">
            <img src={Palm_Img} alt="" />
          </span>
          <span>Escape Trips</span>
        </a>
      </Nav.Item>
      {/* <Nav.Item as="li">
                  <NavLink exact to="#" onClick={logout}>
                    <span className="dasg__imgg">
                      <img src={Logout} alt="" />
                    </span>
                    <span>Log Out</span>
                  </NavLink>
                </Nav.Item> */}
    </>
  );
};

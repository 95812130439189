import React, { useState, useEffect } from "react";
//import "./styles.css";
import { Button, Form, Modal } from "react-bootstrap";
// import LeftpanelMember from "../../../components/leftpanel/left-panel-admin";
// import HeaderPart from "../../../components/layout/header-admin/header";
import "./login.css";
import { Route, Redirect, useHistory } from "react-router-dom";

import { HeaderTwoLogo, LoginBanner, Logo } from "../../assets/images";
import LoadingOverlay from "react-loading-overlay";
import { login } from "../../axios/login.axios";
import { message } from "antd";
import apiConfig from "../../config/api.config";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import axios from "../../axios/axios";

// import AfterLoginDashboard from "../admin-dashboard/dashboard/dashboard";

const LoginPage = (props) => {
  const [token, setToken] = useState(false);
  const navigate = useHistory();
  const [showLoader, setShowLoader] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [credsError, setCredsError] = useState({
    emailerr: "",
    passworderr: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  const emailValidatior = () => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (user.email.trim() === "") {
      message.error("Email is invalid");
      //   setCredsError({ emailerr: "Email is required" });
      return false;
    } else if (reg.test(user.email) === false) {
      message.error("Email is invalid");
      //   setCredsError({ emailerr:  });
      return false;
    }
  };

  const passwordValidator = () => {
    if (user.password.trim() === "") {
      message.error("Password is required");
      //   setCredsError({ passworderr: " });
      return false;
    } else if (user.password.length < 6) {
      message.error("Minimum password length is 6");
      //   setCredsError({ passworderr: "Minimum password length is 6" });
      return false;
    }
  };

  useEffect(() => {
    let sso_token = new URLSearchParams(props.location.search).get("sso_token");
    let action = new URLSearchParams(props.location.search).get("action");
    let token;
    if (sso_token) {
      let data = {
        token: sso_token,
      };
      axios
        .post(`user/sso_login`, data)
        .then((res) => {
          console.log(res.data)
          if (res.data.status) {
            message.success(
              "SSO login was successfull. Redirecting to dashboard..."
            );
            localStorage.setItem("AuthData", JSON.stringify(data));
            localStorage.setItem("AuthToken", res.data.token);
            localStorage.setItem("userDetails", JSON.stringify(res.data.data));
            localStorage.setItem("GPTtoken", res.data.GPTtoken);
            setTimeout(() => {
              window.open("/", "_self");
            }, 1000);
          } else {
            message.error("login failed");
          }
        })
        .catch((err) => {
          console.log("err", err);
          message.error(err.response.data.message);
        });
    } else if (localStorage.getItem("AuthToken")) {
      token = localStorage.getItem("AuthToken");
      // setToken(token)
      window.open("/", "_self");
    } else if (action && action == "logout") {
      message.success("You have been successfully logged out");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [null]);
  const loginUser = async (e) => {
    e.preventDefault();

    let data = {
      email: user.email,
      password: user.password,
      source: "tvi",
    };

    // if (emailValidatior() && passwordValidator())
    {
      await login(data)
        .then((data) => {
          message.success("login successfully");
          localStorage.setItem("AuthData", JSON.stringify(data));
          localStorage.setItem("AuthToken", data.token);
          localStorage.setItem("userDetails", JSON.stringify(data.data));
          localStorage.setItem("GPTtoken", data.GPTtoken);
          window.open("/", "_self");
        })
        .catch((err) => {
          message.error(err);
        });
    }
  };

  const forgotPassword = (e) => {
    e.preventDefault();

    setShowModal(false);

    axios
      .post("/user/send-reset-password-token", { email: resetEmail })
      .then((res) => {
        if (res.data.status) {
          setResetEmail("");
          message.success("please check your mail");
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong!!!!");
      });
  };

  return (
    <div>
      <LoadingOverlay
        active={showLoader}
        className="loading_overlay"
        //spinner= <img src={Logo} />
        text=<p>
          Please be patience while we are redirecting to Member Dashboard.
        </p>
      >
        <Modal
          show={showModal}
          onHide={() => {
            setShowModal(false);
            setResetEmail("");
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Forgot Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={forgotPassword}>
              <Form.Group className="mb-4" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  className="login_field"
                  placeholder="Enter email"
                  value={resetEmail}
                  onChange={(e) => {
                    setResetEmail(e.target.value);
                  }}
                  required
                />
              </Form.Group>
              <div className="d-flex justify-content-end">
                <Button type="submit" variant="primary">
                  Submit
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        <div className="main_cont">
          <div className="login_page">
            <div className="login_page_left">
              <img src={LoginBanner} className="img-fluid" />
            </div>
            <div className="login_page_right">
              <div className="login_page_right_inner">
                <div className="login_inner_logo">
                  <img src={Logo} />
                </div>
                <div className="login_inner_bottom">
                  <Form>
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        className="login_field"
                        placeholder="Enter email"
                        value={user.email}
                        onChange={(e) =>
                          setUser({
                            ...user,
                            email: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        className="login_field"
                        placeholder="Password"
                        value={user.password}
                        onChange={(e) =>
                          setUser({
                            ...user,
                            password: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Remember Me" />
                  </Form.Group> */}
                    <div className="forgot_pass">
                      <button
                        type="button"
                        onClick={() => {
                          setShowModal(true);
                        }}
                      >
                        Forgot Password?
                      </button>
                    </div>
                    <div className="login_submit">
                      <Button
                        variant="primary"
                        type="button"
                        className="sub_log_but"
                        onClick={loginUser}
                      >
                        Log In
                      </Button>
                      <br />
                    </div>
                    <div className="login_submit text-white">
                      New User ? Please register here{" "}
                      <Link to="/plan" className={"text-white ml-1"}>
                        {" "}
                        Sign Up
                      </Link>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center my-5">
                <div className="another_logo">
                  <img src={HeaderTwoLogo} alt="TripValet Logo" />
                  <span>
                    Looking for TripValet?{" "}
                    <a
                      href={`${process.env.ENV_REACT === "PRODUCTION"
                        ? "https://login.tripvalet.com"
                        : "https://login.tripvalet.com"
                        }`}
                    >
                      Login Here
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default LoginPage;

import React, { useState , useEffect} from "react";
import "./affiliate-dashboard.css";
import DataTable from "react-data-table-component";
import { Button } from "react-bootstrap";

  const columns = [
    {
      name: "Description",
      selector: (row) => row.name,
      sortable: true,
     
    },
    {
      name: "Amount($)",
      selector: (row) => row.amount,
      sortable: true,
    
    },
    {
      name: "Received On",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
     
    },
    {
      name: "Enrolled User",
      selector: (row) => row.sourceUser.name+", "+row.sourceUser.email,
      sortable: true,
    
    }
  ];
  
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
        },
        fontSize: "17px",
        color: "#004aad",
      },
    },
  
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
        },
        fontSize: "16px",
      },
    },
  };
  
const CommissionTable = (props) => {

    

    const [tabledata,setTabledata] = useState([])
 
    const setData = () => {   

     setTimeout(() => {
      setTabledata(props.users)
     }, 500);
    
    }
    useEffect(()=>{
      setData()
      console.log(props.users,"table")
    },[])
    return (

        <div className="chart_partts">
            <h2>My Commission Report</h2>    
            <DataTable
                columns={columns}
                data={props.commissionReport}
                //pagination
                striped
                responsive
                customStyles={customStyles}
                subHeaderAlign="right"
                subHeaderWrap
                //paginationDefaultPage={page}
                pagination

              />
        </div>
        );
    };
    export default CommissionTable;
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import './plan.css';
import {
    PaymntSuccess,
} from "../../assets/images";
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useEffect } from 'react';
const PaymentSuceessOrderCheckout = (props) => {
    const iframeRef = React.useRef < HTMLIFrameElement | null > (null);
    const paymentDetails = useSelector(state => state.changeStep.payment)
    const user = useSelector(state => state.changeStep.tempuser)
    const product = useSelector(state => state.product.details)
    const loginURL = useSelector(state => state.changeStep.login)
    const [payment, setPayment] = useState({
        paymnt_type: paymentDetails.payment_method_details ? paymentDetails.payment_method_details.type : "card",
        mobile: user.phone,
        email: user.email,
        amount_paid: "$" + parseInt(paymentDetails.amount) / 100,
        transaction_id: paymentDetails.id,
        receipt: paymentDetails.receipt_url ?? ""
    });
    const [referCode, setReferCode] = useState("");
    useEffect(() => {
        if (localStorage.getItem('referCodeStore')) {
            setReferCode(localStorage.getItem('referCodeStore'));
            const iframe = iframeRef.current;
            if (!iframe) return undefined;

            // not sure if we need to check if the iframe already loaded (e.g. readyState)

            const listener = () => console.log("event listener");

            iframe.addEventListener("load", listener);

            setTimeout(() => {
                localStorage.removeItem('referCodeStore');
                localStorage.removeItem('paramCode');
            }, 1000);

            return () => {
                iframe.removeEventListener("load", listener);
            };
        }
        props.summary(false)
        props.ShowSummaryBlank(false);
        const script = document.createElement("script");

        script.src = window.location.origin + "/custom_script.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    })
    return (

        <div className='sign_up_partts paymnt_parttss'>
            <div className="paymn_success">
                <img src={PaymntSuccess} />
            </div>
            <div className="paymnt_text">
                <h2>Payment Success!</h2>
                <div className='paymnt_type'>
                    <div className='paymnt_inner_type'>
                        <div className='paymnt_inner_leftts'>Payment Type</div>
                        <div className='paymnt_inner_rightts'>{payment.paymnt_type}</div>
                    </div>

                    <div className='paymnt_inner_type'>
                        <div className='paymnt_inner_leftts'>Phone</div>
                        <div className='paymnt_inner_rightts'>{payment.mobile}</div>
                    </div>
                    <div className='paymnt_inner_type'>
                        <div className='paymnt_inner_leftts'>Email Address</div>
                        <div className='paymnt_inner_rightts'>{payment.email}</div>
                    </div>
                    <div className='paymnt_inner_type'>
                        <div className='paymnt_inner_leftts'>Amount Paid</div>
                        <div className='paymnt_inner_rightts'>${product.price}</div>
                    </div>
                    <div className='paymnt_inner_type'>
                        <div className='paymnt_inner_leftts'>Transaction ID</div>
                        <div className='paymnt_inner_rightts'>{payment.transaction_id}</div>
                    </div>
                    <div className='paymnt_inner_type'>
                        {/* <Button variant="primary" type="button"  onClick={() =>{ window.open(payment.receipt,"_blank") }}>
                         Download Payment Reciept
                        </Button > */}
                        <Button variant="primary" type="button" onClick={() => { window.open(loginURL, "_blank") }}>
                            Go To Dashboard
                        </Button >
                    </div>
                    {referCode != '' ? (<>
                        <iframe title="tvi_home_iframe" className="d-none" id="tvi_home" src={`https://tripvaletincentives.com?affiliateLinkParamDataDestroy=destroy`}></iframe>
                    </>) : (<></>)}
                </div>
            </div>
        </div>
    );
};

export default PaymentSuceessOrderCheckout;
import React, { useState, useEffect } from "react";
//import "./styles.css";
import { Button, Form } from "react-bootstrap";
// import LeftpanelMember from "../../../components/leftpanel/left-panel-admin";
// import HeaderPart from "../../../components/layout/header-admin/header";
import "./logout.css";
import { Route, Redirect, useHistory, Link } from "react-router-dom";

import { LoginBanner, Logo } from "../../assets/images";

const LogoutPage = (props) => {
  return (
    <div>
      <div className="main_cont">
        <div className="login_page loader_login">
          <div className="login_page_left w-100 max-100">
            <img src={LoginBanner} className="img-fluid" />
            <div className="trip_text_holder">
              <h3 className="trip_text">
                Thank you for using TripvaletIncentive Member portal. 
                See you soon.<br /> To get back to member dashboard please  <Link to="/login">Login</Link> here{" "}
                
             
              </h3>
            
            </div>
          </div>
          {/* <div className="login_page_right">
            <div className="login_page_right_inner">
              <div className="login_inner_logo">
                <img src={Logo} />
              </div>
              <div className="login_inner_bottom">
                <Form>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      className="login_field"
                      placeholder="Enter email"
                      value={user.email}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          email: e.target.value,
                        })
                      }
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      className="login_field"
                      placeholder="Password"
                      value={user.password}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          password: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Remember Me" />
                  </Form.Group>
                  <div className="forgot_pass">
                    <a href="#">Forgot Password?</a>
                  </div>
                  <div className="login_submit">
                    <Button
                      variant="primary"
                      type="button"
                      className="sub_log_but"
                      onClick={login}
                    >
                      Log In
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LogoutPage;

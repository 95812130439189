import React, { useState, useEffect } from "react";
//import "./styles.css";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import LeftpanelMember from "../../../components/leftpanel/leftpanel-member";
import HeaderPart from "../../../components/layout/header/header";
import LoadingOverlay from "react-loading-overlay";
import "./resources-dashboard.css";


import {
    WixTemplate,
    ShareableImages,
    CertificateGuide,
    ShareContents,
    FaqBanner,
    NextLevelAgent,
    NextLevelRealtor
} from "../../../assets/images";

const ResourcesDashboardPart = () => {
    return (
        <div>
            <HeaderPart />
            <div className="main_cont">
                <LeftpanelMember />
                <div className="right_cont">
                    <div className="right_banner_banner_cnt">
                        <Row>
                            {/* <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="javascript:void(0)" className="banner1_box travel_boxxx">
                                        <img src={WixTemplate} className="img-fluid" />
                                        <div className="banner1_overlay">Wix Website Templates</div>
                                    </a>
                                </div>
                            </Col> */}
                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="/shareable-images" className="banner1_box travel_boxxx">
                                        <img src={ShareableImages} className="img-fluid" />
                                        <div className="banner1_overlay">Shareable Images</div>
                                    </a>
                                </div>
                            </Col>

                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="https://drive.google.com/file/d/1S478XxCKbFzWukkK3qp66A9S494zMCBB/view?usp=sharing" target="_blank" className="banner1_box travel_boxxx">
                                        <img src={CertificateGuide} className="img-fluid" />
                                        <div className="banner1_overlay">Certificate Guide</div>
                                    </a>
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="https://drive.google.com/drive/folders/1HYfosTCgPV1dpP_Pvbj79gcV12qP2A1o" target="_blank" className="banner1_box travel_boxxx">
                                        <img src={ShareContents} className="img-fluid" />
                                        <div className="banner1_overlay">TripValet/TVI Content</div>
                                    </a>
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="https://docs.google.com/document/d/1jZRRCHgXkZZUAJByH01zMHTM68SjtF5Qro7fp8HVkmw/edit?usp=sharing" target="_blank" className="banner1_box travel_boxxx">
                                        <img src={FaqBanner} className="img-fluid" />
                                        <div className="banner1_overlay">Faqs</div>
                                    </a>
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="https://drive.google.com/drive/folders/1HyWfn8-qrQd1x5qKLow5O-GgiKka9lJz?usp=sharing" target="_blank" className="banner1_box travel_boxxx">
                                        <img src={NextLevelRealtor} className="img-fluid" />
                                        <div className="banner1_overlay">Next Level Realtor</div>
                                    </a>
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" className="right_innrBanner">
                                <div className="right_inner_inner_banner">
                                    <a href="https://drive.google.com/drive/folders/1wOF6cPHlDFjne9FjIfZ0FMlc-0PlEPv1?usp=sharing" target="_blank" className="banner1_box travel_boxxx">
                                        <img src={NextLevelAgent} className="img-fluid" />
                                        <div className="banner1_overlay">Next Level Agent</div>
                                    </a>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResourcesDashboardPart;
import React, { useState, useEffect } from "react";
//import "./styles.css";
// import {  Row, Col } from 'react-bootstrap';
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
//import { PDFDownloadLink } from 'react-pdf';
import LeftpanelMember from "../../../../components/leftpanel/leftpanel-member";
import HeaderPart from "../../../../components/layout/header/header";
import "./wix-template.css";
// import { Trip, PDFFile } from "../../../../assets/images";
import axios from "../../../../axios/axios";
import LoadingOverlay from "react-loading-overlay";
import { message } from "antd";
const WixTemplate = () => {
  const [loading, setLoading] = useState(true);
  const [allCals, setAllCals] = useState([]);
  const fetchRes = async () => {
    await axios
      .get(
        `/get-resources`

        // {
        //   headers: {
        //     Authorization: token,
        //   },
        // }
      )
      .then((res) => {
        console.log("get-calendar res...", res.data);
        if (res.data.status) {
          setAllCals(res.data.data);
          message.success("Resource fetched");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    fetchRes();
  }, [loading]);
  return (
    <LoadingOverlay active={loading} spinner text="Loading ...">
      <HeaderPart />
      <div className="main_cont">
        <LeftpanelMember />
        <div className="right_cont">
          <div className="right_banner_banner_cnt">
            <div className="event_heading">
              <h2>PDF Gallery</h2>
            </div>
            <div className="event_image_group">
              {allCals.length > 0 &&
                allCals.map((reso) => {
                  return (
                    <div className="event_image_inner_group">
                      <div className="event_image_inner_box">
                        <a
                          href={reso.resourcePdf}
                          target="_blank"
                          without
                          rel="noreferrer"
                        >
                          <Document file={reso.resourcePdf}>
                            <Page pageNumber={1} />
                          </Document>
                        </a>

                        <p>{reso.title}</p>
                      </div>
                      {/* <p>{reso.title}</p> */}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default WixTemplate;

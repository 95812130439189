import React, { useState , useEffect} from "react";
//import "./styles.css";
import { Row, Col, Form } from "react-bootstrap";

import "./affiliate-dashboard.css";
import {Dollar} from "../../assets/images";

const Commissions = (props) => {
    return (

        <div className="wizard_inner impressions">
            <div className="wizard_inner_inner ">
                <div className="wizard_overlay">
                    <h3>{props.header}</h3>
                    <p>{props.subHeader}</p>
                    <img src={props.icon} />
                </div>
            </div>
        </div>
        );
    };
    export default Commissions;
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import './plan.css';
import { DownArrow } from "../../assets/images";
const BlankOrderSummery = (props) => {


    const [visible, setVisible] = useState(false);


    return (
        <div className='order_summery'>
            <div className='ord_inner'>
                <h3>Order summary</h3>
                <div className='plan_summ'>
                    <div className='plan_left'>Plan</div>
                    <div className='plan_right'></div>
                </div>
                <div className='plan_summ'>
                    <div className='plan_left'>Duration</div>
                    <div className='plan_right'>Until canceled</div>
                </div>

                <div className='coupon_code'><button type='button' className='coupon_but' onClick={() => setVisible(true)}>Enter a coupon code <img src={DownArrow} /></button>
                    <div><Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
                        <Form.Control type="text" placeholder="Enter a coupon code" />
                    </Form.Group><button type="submit" disable className='apply_but'>Apply</button></div>
                </div>
                <div className='total_amnt'>
                    <div className='text_l'>Total</div>
                    <div className='text_r'>$0</div>
                </div>
            </div>
            <div className='secure_check'>
                Secure Checkout
            </div>
        </div>
    );
};

export default BlankOrderSummery;
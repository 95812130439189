import React, { useState, useEffect } from 'react';
import { Container, Modal, Form, Button } from 'react-bootstrap';
import './plan.css';
import { useSelector, useDispatch } from 'react-redux';
import useFormValidation from '../../hooks/useFormValidator';
import { additionInfo } from '../../axios/signUp.axios';
import { setTempUser } from '../../actions/signUp.actions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import axios from "../../axios/axios";
import LoadingOverlay from 'react-loading-overlay';
import { useHistory } from 'react-router-dom';
const stripePromise = loadStripe('pk_test_4wGB5yfKvWI4Xe3wwBxRpEwW');
const OrderCheckoutStripeRedirect = (props) => {
    const history = useHistory();
    const [stripe, setStripe] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [sessionData, setSessionData] = useState({});
    // const product = useSelector(state => state.product.details);
    // const tempUser = useSelector(state => state.changeStep.tempuser)
    useEffect(() => {
        let session_id_stp = localStorage.getItem('session_id_stp');
        let product = localStorage.getItem('product');
        let tempUser = localStorage.getItem('tempUser');
        product = JSON.parse(product);
        tempUser = JSON.parse(tempUser);
        let temp = {
            userDetails: tempUser,
            price: product.price_id,
            coupon: product.coupon,
            priceObject: product.priceObject,
            session_id_stp: session_id_stp
        }
        if (product.coupon)
            temp.coupon = product.coupon
        console.log(temp, "temp")
        axios
            .post(`/session-status-check-registration`, temp)
            .then((res) => {
                console.log(res.data)
                localStorage.removeItem('product');
                localStorage.removeItem('tempUser');
                if (res.data.status) {
                    localStorage.setItem('reg_detail', JSON.stringify(res.data.data))
                    history.push("/order-checkout-payment-success");
                } else {
                    history.push("/order-checkout-payment-failed");
                }
            })
            .catch((err) => {
                console.log("err", err);

            });
    }, [stripe]);

    const dispatch = useDispatch();
    let options = {};


    const [validationReport, validate] = useFormValidation()

    return (
        <div className='sign_up_partts'>

            <LoadingOverlay
                active={stripe}
                spinner
                text='Your Payment is Processing....'
            ></LoadingOverlay>
        </div>
    );
};

export default OrderCheckoutStripeRedirect;
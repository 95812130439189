// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coming_soon_page{
    width: 100%;
    background: rgba(36,57,119,0.3);
}

.coming_heading h2{
    text-align: center;
    color: #243977;
    font-size: 55px;
    font-weight: 700;
}

.coming_heading{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/coming-soon/coming-soon.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,+BAA+B;AACnC;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".coming_soon_page{\n    width: 100%;\n    background: rgba(36,57,119,0.3);\n}\n\n.coming_heading h2{\n    text-align: center;\n    color: #243977;\n    font-size: 55px;\n    font-weight: 700;\n}\n\n.coming_heading{\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

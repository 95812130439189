// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.im_card {
    width: 650px;
    margin: 4rem auto;
    box-shadow: 0px 2px 4px 0px rgb(63 71 86 / 53%);
    padding: 1.8rem;
    max-width: 95%;
}

.im_form .form-label {
    margin: 0;
    font-weight: 500;
}
.im_form .form-control {
    padding: 0;
    border-width: 0 0 1px 0;
    border-radius: 0;
    height: 34px;
    font-size: 14px;
    box-shadow:  none !important;
}

.imf_button {
    padding: 15px;
    text-transform: uppercase;
    font-weight: 500;
    width: 100%;
}
.im_form .form-check-label {
    font-size: 14px;
    font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/pages/signup/lead-generate-css.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,+CAA+C;IAC/C,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,SAAS;IACT,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".im_card {\n    width: 650px;\n    margin: 4rem auto;\n    box-shadow: 0px 2px 4px 0px rgb(63 71 86 / 53%);\n    padding: 1.8rem;\n    max-width: 95%;\n}\n\n.im_form .form-label {\n    margin: 0;\n    font-weight: 500;\n}\n.im_form .form-control {\n    padding: 0;\n    border-width: 0 0 1px 0;\n    border-radius: 0;\n    height: 34px;\n    font-size: 14px;\n    box-shadow:  none !important;\n}\n\n.imf_button {\n    padding: 15px;\n    text-transform: uppercase;\n    font-weight: 500;\n    width: 100%;\n}\n.im_form .form-check-label {\n    font-size: 14px;\n    font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

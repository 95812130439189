export const payments = {
    setProduct: (product) =>{
        return {
            type:"SET",
            payload:product
        }
    },
    setPrice: (price) =>{
        return {
            type:"UPDATE",
            payload:price
        }
    },
    setCoupon: (coupon) =>{
        return {
            type:"SETCOUPON",
            payload:coupon
        }
    }
}
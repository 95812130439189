import React from "react";
import Header from "./header/header";

export default function layout({ children }) {
  return (
    <>
      {/* <Header /> */}
      <div className="content">{children}</div>
    </>
  );
}

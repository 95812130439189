import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { brand_logo_two } from "../../assets/images";
import { Facebook, Instagram, YouTube } from "../../assets/images/icons";
import "./referal_header.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { gi_05 } from "../../assets/images";
import Form from "react-bootstrap/Form";
import LoadingOverlay from "react-loading-overlay";
import axios from "../../axios/axios";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { message } from "antd";

export default function ReferalFooter(props) {
  const affUserId = useSelector((state) => state.changeStep.affUserIdForLead);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [cookies, setCookie] = useCookies(["lead_modal"]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    userId: "",
    lead_first_name: "",
    lead_email: "",
    source: "tvi",
  });
  const handleOnChange = (e) => {
    const { name, value } = e.target;

    formData[name] = value;

    setFormData({ ...formData });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post("/affiliate/lead/save", formData)
      .then((res) => {
        setLoading(false);

        if (res.data.status) {
          setShow(false);
          message.success(res.data.message);
          setFormData({
            userId: "",
            lead_first_name: "",
            lead_email: "",
            source: "tvi",
          });
          setCheck(false);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err, "submitError");
        message.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (affUserId) {
      const expires = new Date();
      expires.setDate(expires.getDate() + 7);
      if (cookies.lead_modal) {
        setShow(false);
      } else {
        setFormData({
          userId: affUserId,
          lead_first_name: "",
          lead_email: "",
          source: "tvi",
        });
        setTimeout(() => {
          setShow(true);
          setCookie("lead_modal", true, { path: "/", expires });
        }, 5000);
      }
    }
  }, [affUserId]);
  return (
    <footer className="footer">
      {/* <div className="footer_top text-center">
        <Container>
          <Row className="justify-content-center">
            <Col md={3} sm={6}>
              <img
                src={brand_logo_two}
                className="img-fluid"
                alt="Brand Logo"
              />
              <div className="btn_section d-grid text-center mt-5">
                <a
                  href="javascript:void(0)"
                  onClick={props.gotoOrderCheckoutForm}
                  className="btn_style_one btn rounded-pill mb-3"
                >
                  <span>SIGN UP NOW</span>
                </a>
                <a
                  href="mailto:support@tripvalet.com"
                  className="btn_style_one btn rounded-pill mb-3"
                >
                  <span>CONTACT US</span>
                </a>
                <a
                  href="/login"
                  className="btn_style_one btn rounded-pill mb-3"
                >
                  <span>MEMBER LOGIN</span>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
      <div className="footer_bottom text-white">
        <Container>
          <Row className="align-items-center">
            <Col md={4}>
              <ul className="fc_details list-unstyled">
                <li>7848 W Sahara Ave Las Vegas, NV 89117</li>
                <li>
                  <a
                    href="mailto:support@tripvalet.com"
                    style={{ color: "#fff" }}
                  >
                    support@tripvalet.com
                  </a>
                </li>
                <li>Tel: 913-732-0557</li>
              </ul>
            </Col>
            <Col md={4}>
              <div className="text-center copyright_info">
                <p className="mb-0">© 2024 TRIPVALET INCENTIVES</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="text-center copyright_info">
                <div className="header_sl d-flex justify-content-center mb-3">
                  <a
                    href="https://www.youtube.com/@tripvaletincentives"
                    target="_blank"
                  >
                    <YouTube />
                  </a>
                  <a
                    href="https://www.facebook.com/tripvaletincentives"
                    target="_blank"
                  >
                    <Facebook />
                  </a>
                  <a
                    href="https://www.instagram.com/tripvalet/"
                    target="_blank"
                  >
                    <Instagram />
                  </a>
                </div>
                {/* <p className="mb-0">© 2024 TRIPVALET INCENTIVES</p> */}
              </div>
            </Col>

          </Row>
        </Container>
      </div>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingOverlay active={loading} spinner text="Loading ...">
            <div className="container full_content_modal">
              <div className="row">
                <div className="col-md-12 text-center">
                  <img width={180} src={gi_05} alt="" srcset="" />
                </div>
                <div className="col-md-12 text-center lead_modal_middle_text">
                  <p>Special Offer Just For YOU!!</p>
                </div>
                <div className="col-md-12 text-center lead_modal_middle_text">
                  <p>Test Drive One Of Our Most Popular Vacations!</p>
                </div>
              </div>
              <Form
                id="formSubmit"
                onSubmit={handleFormSubmit}
                className="pl-5 pr-5"
              >
                <Row>
                  <Col md={12} className="text-center">
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Your Name"
                        required
                        name="lead_first_name"
                        onChange={handleOnChange}
                        value={formData.lead_first_name}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className="text-center">
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Your Email"
                        required
                        name="lead_email"
                        value={formData.lead_email}
                        onChange={handleOnChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className="text-center">
                    <Form.Group className="mb-3 text-left">
                      <Form.Check
                        type="checkbox"
                        required
                        onClick={() => {
                          setCheck(true);
                        }}
                        checked={check}
                        label="I agree to receive information about special offers and new products."
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className="text-center">
                    <div className="d-grid">
                      <Button
                        type="submit"
                        variant="dark"
                        className="lead_modal_button"
                      >
                        Send me a vacation!
                      </Button>
                    </div>
                  </Col>
                  <Col md={12} className="text-center mt-3">
                    <a
                      href="javascript:void(0)"
                      onClick={handleClose}
                      className="lead_modal_href"
                    >
                      <p>No thanks, I don't want to take a vacation :(</p>
                    </a>
                  </Col>
                </Row>
              </Form>
            </div>
          </LoadingOverlay>
        </Modal.Body>
      </Modal>
    </footer>
  );
}

import React, { useEffect } from "react";
import { useState } from "react";
import validator from "validator";

function validation(inputName) {
    let errors = {};
    if (!inputName.firstName) {
        errors.firstName = 'First name is required';
    }
    if (!inputName.lastName) {
        errors.lastName = 'Last name is required';
    }
    if (!inputName.street) {
        errors.street = 'Street name is required';
    }
    if (!inputName.city) {
        errors.city = 'City name is required';
    }
    if (!inputName.state) {
        errors.state = 'State is required';
    }
    if (!inputName.phone) {
        errors.phone = 'Phone is required';
    }
    if (!inputName.plan) {
        errors.plan = 'Plan is required';
    }
    if (!inputName.zipCode) {
        errors.zipCode = 'Zipcode is required';
    }

    return errors;
}

export default validation;
import React, { useEffect, useState } from 'react';

import { Col } from 'react-bootstrap';
import './plan_new.css';
import {
    Logo
} from "../../assets/images";
import CryptoJS from "crypto-js";
import axios from "axios";
import { Helmet } from "react-helmet";

const EmbeddedAppsTVI = (props) => {
    const [flag, Setflag] = useState(false);
    const [conTen, SetconTen] = useState("");
    const [url, Seturl] = useState("");
    const query = new URLSearchParams(window.location.search);
    const queryContext = query.get('context');
    const querySignature = (query.get('signature')) ? query.get('signature') : '';
    const secretKey = 'bb66403d5082c22cf663a48303381c7a';

    let decodedContext;
    useEffect(() => {
        // const script = document.createElement('script');
        // script.src = 'https://eia.followupboss.com/embeddedApps-v1.0.0.js';
        // script.async = true;
        // document.body.appendChild(script);



        // const config = {
        //     headers: {
        //         accept: 'application/json',
        //         authorization: 'Bearer ZmthXzBxY0hWMURWZGhleWtIUGtrZWZKek9TdFVzaVpobGRVeWs6'
        //     }
        // };

        // axios
        //     .get('https://api.followupboss.com/v1/identity', config)
        //     .then(function (response) {
        //         console.log(response.data);
        //     })
        //     .catch(function (error) {
        //         console.error(error);
        //     });


    }, [])
    useEffect(
        () => {
            if (querySignature != '') {
                const isFromFollowUpBoss = (context, signature) => {
                    var calculated = CryptoJS.HmacSHA256(context, secretKey).toString();

                    return signature === calculated;
                }

                decodedContext = atob(queryContext);
                var isValid = isFromFollowUpBoss(queryContext, querySignature);

                console.log(decodedContext, 'decodedContext');
                console.log(isValid, 'isValid');


                // eslint-disable-next-line react-hooks/exhaustive-deps
                Seturl(`https://login.tripvaletincentives.com/embedded-apps/tvi?context=${queryContext}&signature=${querySignature}`);
            }
        }, [querySignature])
    useEffect(
        () => {
            Setflag(true);
            SetconTen(decodedContext)
        }, [decodedContext])
    return (
        //////////// iframe //////////
        <div className="">
            {/* <Helmet>
                <script type="text/javascript" src="https://eia.followupboss.com/embeddedApps-v1.0.0.js" async></script>
            </Helmet> */}
            <Col md={12} className='text-center p-4'>
                <img src={Logo} />
            </Col>
            <Col md={12} className='text-center'>
                <a href="https://login.tripvaletincentives.com/login" target='_blank' type='button' className='btn btn-primary'>Connect to Tripvalet Incentives</a>
            </Col>
            <Col md={12} className='text-center mt-3'>
                <a href="http://www.tviforagents.com" target='_blank' type='button' className='btn btn-primary'>Signup for Tripvalet Incentives</a>
            </Col>
            <Col md={12} className='text-center mt-3'>
                <p>Need help?</p>
                <p>Email us at <a href="mailto:support@tripvaletincentives.com">support@tripvaletincentives.com</a></p>
            </Col>

            {/* {(flag) ? (<> */}
            {/* <iframe sandbox="allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-downloads" style={{ height: '512px', display: 'block' }}></iframe> */}
            {/* </>) : (<></>)} */}
        </div>
    );
};

export default EmbeddedAppsTVI;
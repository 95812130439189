export const products = {
    // LIVE PRODUCTS
    "tvi": [
        {
            id: "prod_NQflUMfj9Gcecq",
            name: "TripValet Incentives Monthly",
            price: "price_1MfoQCIB7ByDDUZGj3hbvrnj"
        },
        {
            id: "prod_NsMGbe7V367KBO",
            name: "TVI 3 Month Membership",
            price: "price_1N6bYOIB7ByDDUZG0iqEZgsX"
        },
        {
            id: "prod_NsMILnNll1Mh4X",
            name: "TVI 6 Month Membership",
            price: "price_1N6ba0IB7ByDDUZGgA5ouu4u"
        },
        {
            id: "prod_NsMKjiK25jqnby",
            name: "TVI 12 Month Membership",
            price: "price_1N6bbaIB7ByDDUZGTbMeJjqh"
        },
        {
            id: "prod_NsMKjiK25jqnby",
            name: "TVI 12 Month Membership",
            price: "price_1PT6JWIB7ByDDUZG4fD9jist"
        }
    ]
    // TEST PRODUCTS
    //  "tvi" :  [
    //         {
    //             id:"prod_NRFvsRFyxkpUz4",
    //             name: "TripValet Incentives PRO Escape",
    //             price:"price_1MgNcnIB7ByDDUZGPmelkquH"
    //         },
    //         {
    //             id:"prod_NUegu9JPgU00Mn",
    //             name: "TripValet Incentives PRO Escape",
    //             price:"price_1MjiaIIB7ByDDUZGUBi5LYUz"
    //         },
    //         {
    //             id:"prod_NS3YkqqAZMfYoJ",
    //             name: "TripValet Incentives PRO Escape",
    //             price:"price_1Mh9RBIB7ByDDUZGToqQ6RIi"
    //         }
    //     ]
}


import React, { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import './plan.css';
import {
    PaymntSuccess, TviWLogo
} from "../../assets/images";
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import ReactPlayer from 'react-player';
import Vid from "../../assets/video/file.mp4";
const OrderCheckoutPaymentSuccess = (props) => {

    let alldetails = localStorage.getItem('reg_detail');
    alldetails = JSON.parse(alldetails);
    const loginURL = alldetails.dashboardURL;
    const [payment, setPayment] = useState({
        mobile: alldetails.user.phone,
        email: alldetails.user.email,
        amount_paid: parseInt(alldetails.paymentDetails.amount_total) / 100,
        transaction_id: alldetails.paymentDetails.id,
        receipt: alldetails.paymentDetails.receipt_url ?? ""
    });
    useEffect(() => {
        const script = document.createElement("script");

        script.src = window.location.origin + "/custom_script.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    })
    return (

        <div className='plan_parts'>
            <div className='video_bg1 plan_bg_box'>

                <ReactPlayer
                    width={'100%'}
                    height='100%'
                    url={Vid}
                    playing={true}
                    muted={true}
                    loop
                />

            </div>
            <div className='loves_travel'>
                <Container>
                    <div className='loves_travel_inner'>
                        <h2>EVERYONE LOVES TRAVEL.</h2>
                        <div className='tvi_w_logo'>
                            <img src={TviWLogo} alt="" />
                        </div>
                    </div>
                </Container>
                <div className='trip_membership'>
                    <div className='sign_up_partts paymnt_parttss'>
                        <div className="paymn_success">
                            <img src={PaymntSuccess} />
                        </div>
                        <div className="paymnt_text">
                            <h2>Payment Success!</h2>
                            <div className='paymnt_type'>
                                <div className='paymnt_inner_type'>
                                    <div className='paymnt_inner_leftts'>Phone</div>
                                    <div className='paymnt_inner_rightts'>{payment.mobile}</div>
                                </div>
                                <div className='paymnt_inner_type'>
                                    <div className='paymnt_inner_leftts'>Email Address</div>
                                    <div className='paymnt_inner_rightts'>{payment.email}</div>
                                </div>
                                <div className='paymnt_inner_type'>
                                    <div className='paymnt_inner_leftts'>Amount Paid</div>
                                    <div className='paymnt_inner_rightts'>${payment.amount_paid}</div>
                                </div>
                                <div className='paymnt_inner_type'>
                                    <div className='paymnt_inner_leftts'>Transaction ID</div>
                                    <div className='paymnt_inner_rightts'>{payment.transaction_id}</div>
                                </div>
                                <div className='paymnt_inner_type'>
                                    {/* <Button variant="primary" type="button"  onClick={() =>{ window.open(payment.receipt,"_blank") }}>
                         Download Payment Reciept
                        </Button > */}
                                    <Button variant="primary" type="button" onClick={() => {
                                        localStorage.removeItem('reg_detail');
                                        window.open(loginURL, "_blank")
                                    }}>
                                        Go To Dashboard
                                    </Button >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        // <div className='sign_up_partts paymnt_parttss'>
        //     <div className="paymn_success">
        //         <img src={PaymntSuccess} />
        //     </div>
        //     <div className="paymnt_text">
        //         <h2>Payment Success!</h2>
        //         <div className='paymnt_type'>
        //             <div className='paymnt_inner_type'>
        //                 <div className='paymnt_inner_leftts'>Mobile</div>
        //                 <div className='paymnt_inner_rightts'>{payment.mobile}</div>
        //             </div>
        //             <div className='paymnt_inner_type'>
        //                 <div className='paymnt_inner_leftts'>Email</div>
        //                 <div className='paymnt_inner_rightts'>{payment.email}</div>
        //             </div>
        //             <div className='paymnt_inner_type'>
        //                 <div className='paymnt_inner_leftts'>Amount Paid</div>
        //                 <div className='paymnt_inner_rightts'>${payment.amount_paid}</div>
        //             </div>
        //             <div className='paymnt_inner_type'>
        //                 <div className='paymnt_inner_leftts'>Transaction Id</div>
        //                 <div className='paymnt_inner_rightts'>{payment.transaction_id}</div>
        //             </div>
        //             <div className='paymnt_inner_type'>
        //                 {/* <Button variant="primary" type="button"  onClick={() =>{ window.open(payment.receipt,"_blank") }}>
        //                  Download Payment Reciept
        //                 </Button > */}
        //                 <Button variant="primary" type="button" onClick={() => {
        //                     localStorage.removeItem('reg_detail');
        //                     window.open(loginURL, "_blank")
        //                 }}>
        //                     Go To Dashboard
        //                 </Button >
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
};

export default OrderCheckoutPaymentSuccess;
import React, { useState, useEffect } from 'react';
import { Container, Modal, Form, Button } from 'react-bootstrap';
import './plan.css';
import { useSelector, useDispatch } from 'react-redux';
import useFormValidation from '../../hooks/useFormValidator';
import { additionInfo } from '../../axios/signUp.axios';
import { setTempUser } from '../../actions/signUp.actions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import LoadingOverlay from 'react-loading-overlay';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import axios from "../../axios/axios";
// const stripePromise = loadStripe(process.env.STRIPE_PUBLISH_KEY);
const OrderCheckoutStripeCheckOutForm = (props) => {
    const [stripe, setStripe] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [sessionData, setSessionData] = useState({});
    const product = useSelector(state => state.product.details);
    const tempUser = useSelector(state => state.changeStep.tempuser)
    useEffect(() => {
        localStorage.setItem('product', JSON.stringify(product))
        localStorage.setItem('tempUser', JSON.stringify(tempUser))
        let data = {
            source: 'tvi',
            price_id: product.price_id,
            email: tempUser.email
            // price_id: "price_1PVSxiIB7ByDDUZGRUdhowHO"
        };
        axios
            .post(`/create-checkout-session`, data)
            .then((res) => {
                setClientSecret(res.data.data.url)
                setSessionData(res.data.data);
                localStorage.setItem('session_id_stp', res.data.data.id)
            })
            .catch((err) => {
                console.log("err", err);

            });
    }, []);

    const dispatch = useDispatch();
    let options = {};

    useEffect(() => {
        if (clientSecret != '') {

            window.location.href = clientSecret;
        }
    }, [clientSecret]);


    const [validationReport, validate] = useFormValidation()

    return (
        <div className='sign_up_partts'>
            <LoadingOverlay
                active={stripe}
                spinner
                text='Redirecting..please wait.....'
            ></LoadingOverlay>

        </div>
    );
};

export default OrderCheckoutStripeCheckOutForm;
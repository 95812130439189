import React, { useState , useEffect} from "react";
//import "./styles.css";
import { Row, Col, Form } from "react-bootstrap";

import "./affiliate-dashboard.css";
import {CommissionsImg,} from "../../assets/images";

const Signup = () => {
    return (

        <div className="wizard_inner commissions">
            <div className="wizard_inner_inner ">
                <div className="wizard_overlay">
                    <h3>2400 </h3>
                    <p>Sign up </p>
                    <img src={CommissionsImg} />
                </div>
            </div>
        </div>
        );
    };
    export default Signup;
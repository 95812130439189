// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.affiliate_facing h2 {
  font-size: 24px;
  color: #1c1c1c;
  display: inline-block;
  padding-bottom: 10px;
  border-bottom: 4px solid #1c1c1c;
  margin-bottom: 0px;
}

.affiliate_facing p {
  font-size: 16px;
  color: rgb(69, 6, 172);
  margin: 20px 0;
}

.embed_code_inner h3 {
  font-size: 18px;
  color: #1c1c1c;
  font-weight: 600;
}

.label_box {
  position: relative;
  background: #dcdada;
  padding: 8px;
  border-radius: 6px;
}

.copy {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.embed_code_inner {
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/embed-code-page/embed-code-page.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;EACd,qBAAqB;EACrB,oBAAoB;EACpB,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".affiliate_facing h2 {\n  font-size: 24px;\n  color: #1c1c1c;\n  display: inline-block;\n  padding-bottom: 10px;\n  border-bottom: 4px solid #1c1c1c;\n  margin-bottom: 0px;\n}\n\n.affiliate_facing p {\n  font-size: 16px;\n  color: rgb(69, 6, 172);\n  margin: 20px 0;\n}\n\n.embed_code_inner h3 {\n  font-size: 18px;\n  color: #1c1c1c;\n  font-weight: 600;\n}\n\n.label_box {\n  position: relative;\n  background: #dcdada;\n  padding: 8px;\n  border-radius: 6px;\n}\n\n.copy {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n}\n\n.embed_code_inner {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

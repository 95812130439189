import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Certificatebanner, TviWLogo } from "../../assets/images";
import "./augeo-redeem.css";
import axios from "../../axios/axios";
import LoadingOverlay from "react-loading-overlay";
import { message } from "antd";
import { useParams, useHistory } from "react-router-dom";
import validation from "./augeo-form-validation";
import { Buffer } from "buffer";


const AugeoRedeem = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [prices, setPrices] = useState([]);
    const [errors, setErrors] = useState({});

    const { rec_name, email, certiId, referral_code, sender_id } = useParams();

    const [input, setInput] = useState({
        firstName: "",
        lastName: "",
        street: "",
        city: "",
        state: "",
        zipCode: "",
        phone: "",
        email: email,
        plan: "",
        certificate_id: certiId,
        sender_id: sender_id,
    });

    const getFirstAndLastName = (fullName) => {
        fullName = fullName.trim();
        const nameParts = fullName.split(' ');
        if (nameParts.length === 1) {
            return { firstName: nameParts[0], lastName: '' };
        }
        const firstName = nameParts[0];
        const lastName = nameParts[nameParts.length - 1];
        return { firstName, lastName };
    }

    useEffect(() => {
        getPrice();
        const buffer = Buffer.from(rec_name, "base64");
        const originalValue = buffer.toString("utf-8");
        console.log(originalValue);
        let nameData = getFirstAndLastName(originalValue);
        setInput({
            ...input,
            firstName: nameData.firstName,
            lastName: nameData.lastName
        })
    }, []);

    const handleOnChange = (e) => {
        let { name, value } = e.target;

        setInput({
            ...input,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validation(input);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        }
        console.log(input);
        let decodeReq = Buffer.from(JSON.stringify(input), "utf-8").toString("base64");
        console.log(decodeReq);
        history.push(`/augeo-certificate-redeem-payment/${decodeReq}`)
        // setLoading(true);

        // axios
        // .post("/reedem-travel-certificate", input)
        // .then((res) => {
        //     setLoading(false);
        //     if (res.data.status) {
        //         message.success(res.data.message);
        //         window.location.pathname = "thank-you";
        //     } else {
        //         message.error(res.data.message);
        //     }
        // })
        // .catch((err) => {
        //     console.error(err);
        //     setLoading(false);
        //     message.error("Something Went Wrong...");
        // });
    };

    const getPrice = () => {

        setLoading(true);

        axios
            .get("/user/get-augeo-price")
            .then((res) => {
                setLoading(false);
                if (res.data.status) {
                    setPrices(res.data.data.tvi)
                }
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
                message.error("Something Went Wrong...");
            });
    };

    // console.log("input", input);

    return (
        <section className="tvi_redeem_augeo">
            <LoadingOverlay
                className="d-flex flex-wrap"
                spinner
                active={loading}
                text="Loading..."
            >
                <div className="redeem_left">
                    <div className="redeem_left_inner_augeo">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group
                                className="mb-4 redeem__form"
                                controlId="formBasicEmail"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Enter First Name"
                                    className="redeem__field"
                                    required
                                    name="firstName"
                                    value={input.firstName}
                                    onChange={handleOnChange}
                                />
                                <Form.Label>Your First Name</Form.Label>
                                {errors.firstName && <p className="text-danger">{errors.firstName}</p>}
                            </Form.Group>

                            <Form.Group
                                className="mb-4 redeem__form"
                                controlId="formBasicEmail"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Last Name"
                                    className="redeem__field"
                                    required
                                    name="lastName"
                                    value={input.lastName}
                                    onChange={handleOnChange}
                                />
                                <Form.Label>Your Last Name</Form.Label>
                                {errors.lastName && <p className="text-danger">{errors.lastName}</p>}
                            </Form.Group>
                            <Form.Group
                                className="mb-4 redeem__form"
                                controlId="formBasicEmail"
                            >
                                <Form.Control
                                    type="email"
                                    placeholder="Enter Email"
                                    className="redeem__field"
                                    required
                                    disabled
                                    name="email"
                                    value={input.email}
                                    onChange={handleOnChange}
                                />
                                <Form.Label>Your Email</Form.Label>
                            </Form.Group>
                            <Form.Group
                                className="mb-4 redeem__form"
                                controlId="formBasicEmail"
                            >
                                <Form.Control
                                    type="number"
                                    placeholder="Enter Phone Number"
                                    className="redeem__field"
                                    required
                                    name="phone"
                                    value={input.phone}
                                    onChange={handleOnChange}
                                />
                                <Form.Label>Your Phone Number</Form.Label>
                                {errors.phone && <p className="text-danger">{errors.phone}</p>}
                            </Form.Group>
                            <Form.Group
                                className="mb-4 redeem__form"
                                controlId="formBasicEmail"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Street Name"
                                    className="redeem__field"
                                    required
                                    name="street"
                                    value={input.street}
                                    onChange={handleOnChange}
                                />
                                <Form.Label>Your Street Name</Form.Label>
                                {errors.street && <p className="text-danger">{errors.street}</p>}
                            </Form.Group>

                            <Form.Group
                                className="mb-4 redeem__form"
                                controlId="formBasicEmail"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Enter City Name"
                                    className="redeem__field"
                                    required
                                    name="city"
                                    value={input.city}
                                    onChange={handleOnChange}
                                />
                                <Form.Label>Your City Name</Form.Label>
                                {errors.city && <p className="text-danger">{errors.city}</p>}
                            </Form.Group>

                            <Form.Group
                                className="mb-4 redeem__form"
                                controlId="formBasicEmail"
                            >
                                <Form.Select aria-label="Default select example"
                                    type="text"
                                    placeholder="Enter State Name"
                                    className="redeem__field form-control"
                                    required
                                    name="state"
                                    value={input.state}
                                    onChange={handleOnChange}>
                                    <option value="">Select State</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA" selected>California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </Form.Select>
                                <Form.Label>Your State Name</Form.Label>
                                {errors.state && <p className="text-danger">{errors.state}</p>}
                            </Form.Group>

                            <Form.Group
                                className="mb-4 redeem__form"
                                controlId="formBasicEmail"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Zip Code"
                                    className="redeem__field"
                                    required
                                    name="zipCode"
                                    value={input.zipCode}
                                    onChange={handleOnChange}
                                />
                                <Form.Label>Your Zip Code</Form.Label>
                                {errors.zipCode && <p className="text-danger">{errors.zipCode}</p>}
                            </Form.Group>
                            <Form.Group
                                className="mb-4 redeem__form"
                                controlId="formBasicEmail"
                            >
                                <Form.Select aria-label="Default select example"
                                    type="text"
                                    placeholder="Choose Plan"
                                    className="redeem__field form-control"
                                    required
                                    name="plan"
                                    value={input.plan}
                                    onChange={handleOnChange}>
                                    <option value="">Select Plan</option>
                                    {prices.map((priceItem) => {
                                        return (
                                            <option value={priceItem.id}>{priceItem.name}</option>
                                        )
                                    })}
                                </Form.Select>
                                <Form.Label>Select Your Plan</Form.Label>
                                {errors.plan && <p className="text-danger">{errors.plan}</p>}
                            </Form.Group>
                            <div className="redeem_submit">
                                <Button variant="primary" type="submit">
                                    Go To Payment
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="redeem_right">
                    <div className="redeem_banner">
                        <img alt="Certificatebanner" src={Certificatebanner} />
                    </div>
                    <div className="redeem_top_text">
                        <img alt="LOGO" src={TviWLogo} />
                        <div className="cert_trav">
                            <h2>Ready to activate your Restaurant and Entertainment Benefits?</h2>
                            <p>Choose from thousands of worldwide destinations.</p>
                        </div>
                        <h3>
                            " Travel More, <span>Pay Less! "</span>
                        </h3>
                    </div>
                </div>
            </LoadingOverlay>
        </section>
    );
};
export default AugeoRedeem;

import React, { useState , useEffect} from "react";
//import "./styles.css";
import { Row, Col, Form } from "react-bootstrap";

import "./affiliate-dashboard.css";
import {ClickImg,} from "../../assets/images";

const Click = () => {
    return (

        <div className="wizard_inner click">
            <div className="wizard_inner_inner">
                {/* <img src={ClickImg} /> */}
                <div className="wizard_overlay">
                    <h3>5395</h3>
                    <p>clicks</p>
                    <img src={ClickImg} />
                </div>
            </div>
        </div>
        );
    };
    export default Click;
import React, { useState , useEffect} from "react";
//import "./styles.css";
import { Row, Col, Form } from "react-bootstrap";
import Click from "./click";
import Signup from "./signup";
import Commissions from "./commissions";
import Refund from "./refund";
import "./affiliate-dashboard.css";
import {Dollar,ClickImg,CommissionsImg,Redeem} from "../../assets/images";
import Widget from "./widget";


const Wizard = (props) => {

    
    const getStats = async  () => {   
        // setprops.Widgets(props.widgetData)      
        // SetAffiliateUrl(props.url)
    }

    useEffect(()=>{
        getStats()
    },[])

    return (

        <section className="wizard_partts">
            <Widget icon={ClickImg} header={props.Widgets.click.value} subHeader={props.Widgets.click.subHeader}  />
            <Widget icon={CommissionsImg} header={props.Widgets.signup.value} subHeader={props.Widgets.signup.subHeader} />
            <Widget icon={Dollar} header={props.Widgets.commission.sign + " " +props.Widgets.commission.value }  subHeader={props.Widgets.commission.subHeader} className="impression" />
            <Widget icon={Redeem} header={props.Widgets.refund.sign + " " +props.Widgets.refund.value } subHeader={props.Widgets.refund.subHeader} />
                     
        </section>
        
        );
    };
    export default Wizard;
import React from "react";

import {
    TviWLogo,
} from "../../assets/images";
import "./augeo-redeem.css";


const ThankYouAugeo = () => {
    let redirectUrl = new URLSearchParams(window.location.search).get("url");
    return (
        <section className="thank_you_page_augeo">
            <div className="thank_you_inner_sec">
                <div className="tvi_logo">
                    <img src={TviWLogo} />
                </div>
                <p className="top_para"><b>Thank you!</b></p>
                <p className="top_para">You're on your way to booking your next vacation!</p>

                <p className="bottom_para">Please check your email for further instructions.  If you don't see it right away, be sure and check your spam or junk (just in case).</p>
                <div className="button_section">
                    <button onClick={() => {
                        window.location.href = `${redirectUrl}`;
                    }}>Go To Access Benefits</button>
                </div>
            </div>
        </section>
    );
};
export default ThankYouAugeo;
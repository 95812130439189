import React, { useState } from 'react';
import { Container, Modal, Form, Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import './plan.css';
import ReactReadMoreReadLess from "react-read-more-read-less";
import "react-datepicker/dist/react-datepicker.css";
import AdditionalInfo from "./additional-info";
import OrderSummeryOrderCheckout from './order-summery-order-checkout';
import SignUp from "./sign-up";
import Payment from "./payment";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PaymentSuccess from './payment-success-order-checkout';
import LoadingOverlay from 'react-loading-overlay';
import { Buffer } from 'buffer';
import {
    TviWLogo,
    Facebook,
    Google,
    EmailIcon,
} from "../../assets/images";
import Vid from "../../assets/video/file.mp4";
import { useSelector, useDispatch } from 'react-redux';
import { changeStep, setCode } from '../../actions/signUp.actions';
import { payments } from '../../actions/payment.actions'
import { useEffect } from 'react';
import { getTokenDetails } from '../../axios/payment.axios';
import BlankOrderSummery from './blank-order-summery';
import OrderCheckoutSignUp from './order-checkout-sign-up';
import { getPrice } from '../../axios/payment.axios';

const OrderCheckoutPart = () => {
    let affiliateCode = new URLSearchParams(window.location.search).get("encode");
    const [show, setShow] = useState(false);
    const [showSummary, setShowSummary] = useState(false)
    const [showSummaryBlank, setShowSummaryBlank] = useState(true)
    const [coupon, setCoupon] = useState(undefined)
    const product = useSelector(state => state.product.details)
    // const initialPrice = product.price



    // const [formWizard,setFormWizard] = useState("signUp");
    const dispatch = useDispatch();

    const [plan, setPlan] = useState()
    const [couponLoader, setCouponLoader] = useState(false)
    const [validClass, setValidClass] = useState("")

    const [loader, setLoader] = useState(false)

    const currentForm = useSelector(state => state.changeStep)
    const getForm = (forname) => {
        dispatch(changeStep(forname))
    }
    const showHideLoader = (status) => {
        setLoader(status)
    }
    const changeCouponLoader = (status) => {
        setCouponLoader(status)
    }
    const handleCoupon = async (coupon) => {
        try {
            showHideLoader(true)

            if (!coupon) {
                showHideLoader(false)
                NotificationManager.error("No Coupon given");
                return 0;
            }
            let details = await getTokenDetails(coupon, product.price_id)
                .catch(err => {
                    showHideLoader(false)
                    NotificationManager.error(err);
                })
            let newPrice

            if (details) {
                if (details.amount_off) {
                    newPrice = product.initialPrice - parseInt(details.amount_off / 100)
                    console.log(product.initialPrice)
                    if (isNaN(newPrice)) {
                        newPrice = 0;
                    }
                }
                else
                    newPrice = product.initialPrice - parseInt(parseInt(product.initialPrice) * details.percent_off / 100)
                if (isNaN(newPrice)) {
                    newPrice = 0;
                }
                dispatch(payments.setPrice(newPrice))
                showHideLoader(false)
                NotificationManager.success("Coupon applied");
                setCoupon(details.id)
                dispatch(payments.setCoupon(details.id))
                setValidClass("valid")
                console.log(newPrice);
            }
            else {
                dispatch(payments.setPrice(product.initialPrice))
                dispatch(payments.setCoupon(undefined))
                setValidClass("invalid")
            }
        } catch (error) {
            showHideLoader(false)
            NotificationManager.error(error);
            setValidClass("invalid")
            dispatch(payments.setCoupon(undefined))
        }

    }
    const handleClick = (message, status) => {
        if (status)
            NotificationManager.success(message);
        else {
            NotificationManager.error(message);
        }
    }
    useEffect(() => {
        getForm("signUp")
        console.log('all ok done')
        const handleBeforeUnload = (event) => {
            const message = 'Are you sure you want to leave?';
            event.returnValue = message; // For most browsers
            return message; // For some older browsers
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        if (affiliateCode) {
            dispatch(setCode(Buffer.from(affiliateCode, 'base64').toString('utf-8')))
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [])

    const setProduct = async (event) => {
        setLoader(true);
        console.log(event.target[event.target.selectedIndex].getAttribute('data-price'))
        event.preventDefault()
        let price_id = event.target[event.target.selectedIndex].getAttribute('data-price')
        getPrice(price_id)
            .then(response => {
                let name = event.target[event.target.selectedIndex].getAttribute('data-name');
                let product_id = event.target[event.target.selectedIndex].getAttribute('data-name')

                let product = {
                    product_id,
                    name,
                    price: response.data,
                    price_id,
                    initialPrice: response.data,
                    priceObject: response.metadata
                }
                //   console.log(product,"product")
                dispatch(payments.setProduct(product))
                //   window.open("/check-out")

                // history.push("/check-out")
                setShowSummary(true)
                setLoader(false);

            })
            .catch(err => {
                setLoader(false)
                NotificationManager.error("Something went wrong")
            })




    }

    const getWizard = () => {
        console.log(product)
        switch (currentForm.formWizard) {
            case "signUp":
                return (<OrderCheckoutSignUp setProduct={setProduct} changeWizard={getForm} notify={handleClick} loader={showHideLoader} />)
                break;
            case "aInfo":
                return (<AdditionalInfo changeWizard={getForm} notify={handleClick} loader={showHideLoader} />)
                break;
            case "payment":
                return (<Payment changeWizard={getForm} notify={handleClick} loader={showHideLoader} />)
                break;
            case "final":
                return (<Payment changeWizard={getForm} notify={handleClick} loader={showHideLoader} />)

            case "success":
                return (<PaymentSuccess changeWizard={getForm} notify={handleClick} loader={showHideLoader} summary={setShowSummary} ShowSummaryBlank={setShowSummaryBlank} />)
                break;
            default:
                break;
        }
    }



    return (
        <div>
            <div className='plan_parts'>
                <div className='video_bg1 plan_bg_box'>

                    <ReactPlayer
                        width={'100%'}
                        height='100%'
                        url={Vid}
                        playing={true}
                        muted={true}
                        loop
                    />

                </div>
                <div className='loves_travel'>
                    <Container>
                        <div className='loves_travel_inner'>
                            <h2>EVERYONE LOVES TRAVEL.</h2>
                            <div className='tvi_w_logo'>
                                <img src={TviWLogo} alt="" />
                            </div>
                            <div className='trip_membership'>
                                <LoadingOverlay
                                    active={loader}
                                    spinner
                                    text='Loading...'
                                >
                                    <div className='check_out_partts'>
                                        <h2>Checkout</h2>
                                        <div className='sign_order'>
                                            {
                                                getWizard()
                                            }
                                            {
                                                (showSummaryBlank) ?
                                                    (showSummary) ? <OrderSummeryOrderCheckout validClass={validClass} product={product} handleApply={handleCoupon} handleLoader={changeCouponLoader} /> : <BlankOrderSummery />
                                                    : ''
                                            }

                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </div>

                        </div>

                    </Container>

                </div>

            </div>
        </div>
    );
};

export default OrderCheckoutPart;